import { RouteConfig } from 'vue-router';
import { LayoutBlock, LayoutBlockKeepAlive } from '@/layout';

const datacenterRouting: RouteConfig[] = [
    {
        path: 'datacenter',
        meta: { title: "数据中心" },
        component: LayoutBlockKeepAlive,
        redirect: '/app/datacenter/signingfee',
        children: [
            { path: 'signingfee', component: () => import('./signingfee/list.vue'), meta: { title: "签署费用统计(自然人)" } },
            { path: 'unnaturalsigningfee', component: () => import('./unnaturalsigningfee/list.vue'), meta: { title: "签署费用统计(非自然人)" } },
            { path: 'invoicing', component: () => import('./invoicing/list.vue'), meta: { title: "开票数据统计" } },
            { path: 'companyInvoice', component: () => import('./companyInvoice/list.vue'), meta: { title: "签约公司开票统计" } },
            { path: 'taskshare', component: () => import('./taskshare/list.vue'), meta: { title: "任务分享统计" } },
            { path: 'smsusage', component: () => import('./smsusage/list.vue'), meta: { title: "短信用量统计" } },
            { path: 'salarypay', component: () => import('./salarypay/list.vue'), meta: { title: "发放明细统计" } },
        ]
    },
];
export default datacenterRouting;
