<template>
  <!-- <a-spin :spinning="spinning"> -->
  <section>
    <div class="modal-header">
      <div class="modal-title" v-if="params.type === 'edit'">
        {{ l("Edit") }}
      </div>
      <div class="modal-title" v-if="params.type === 'preview'">
        {{ l("查看") }}
      </div>
      <div class="modal-title" v-if="params.type === 'create'">
        {{ l("创建") }}
      </div>
      <div class="modal-title" v-if="params.type === 'others'">
        {{ l("创建") }}
      </div>
    </div>
    <div class="modalform-scroll">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :form="form">
        <a-row :gutter="12" class="form-wrapper">
          <a-col :span="item.span ? item.span : 12" v-for="(item, index) in list" :key="index">
            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'commonInput'">
              <a-input :disabled="item.disabled" :placeholder="l('')" @change="item.changeFn" v-decorator="[
                `${item.value}`,
                {
                  rules:
                    item.rules && item.rules.length ? item.rules : defaultRules,
                },
                { defaultValue: item.defaultValue },
              ]" />
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'numberInput'">
              <a-input-number :disabled="item.disabled" :min="item.min" :max="returnNumerInput(item.max)"
                @change="item.changeFn" style="width: 100%" v-decorator="[
                  `${item.value}`,
                  {
                    rules:
                      item.rules && item.rules.length ? item.rules : defaultRules,
                    initialValue: item.defaultValue,
                  },
                ]" />
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'switch'">
              <!-- <a-switch :disabled="item.disabled" v-model="item.value" /> -->
              <a-switch v-decorator="[`${item.value}`, { valuePropName: 'checked' }]" />
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'datePicker'">
              <!-- <a-date-picker
                  :disabled="item.disabled"
                  v-model="item.value"
                  type="date"
                  placeholder="请选择时间"
                  style="width: 100%"
                  @change="item.changeFn"
                /> -->
              <a-date-picker :disabled="item.disabled" :placeholder="'请选择日期'" @change="item.changeFn" v-decorator="[
                `${item.value}`,
                {
                  rules: item.rules && item.rules.length ? [...item.rules] : [],
                },
              ]" style="width: 100%" />
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'monthPicker'">
              <a-month-picker :disabled="item.disabled" :placeholder="'请选择日期'" @change="item.changeFn"
                valueFormat="YYYY-MM" v-decorator="[
                  `${item.value}`,
                  {
                    rules: item.rules && item.rules.length ? [...item.rules] : [],
                  },
                ]" style="width: 100%" />
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'rangeDatePicker'">
              <a-range-picker :disabled="item.disabled" :placeholder="['开始日期', '结束日期']" valueFormat="YYYY-MM-DD"
                @change="item.changeFn" v-decorator="[
                  `${item.value}`,
                  {
                    rules: item.rules && item.rules.length ? [...item.rules] : [],
                  },
                ]" style="width: 100%" />
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'textarea'">
              <a-input type="textarea" :disabled="item.disabled" :placeholder="l('请输入')" v-decorator="[
                `${item.value}`,
                {
                  rules:
                    item.rules && item.rules.length
                      ? [...item.rules, defaultRules]
                      : defaultRules,
                },
              ]" />
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'commonSelect'"
              :labelCol="{ span: item.labelSpan ? item.labelSpan : 6 }">
              <a-select show-search v-decorator="[
                `${item.value}`,
                {
                  rules:
                    item.rules && item.rules.length ? item.rules : defaultRules,
                  initialValue: item.defaultValue,
                },
              ]" option-filter-prop="children" :filter-option="filterOption" placeholder="请选择"
                :disabled="item.disabled" @change="item.changeFn">
                <a-select-option :value="it.value" v-for="(it, i) in item.defaultSelectList" :key="i">
                  {{ it.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'commonTreeSelect'">
              <a-tree-select v-decorator="[
                `${item.value}`,
                {
                  rules:
                    item.rules && item.rules.length ? item.rules : defaultRules,
                  initialValue: item.defaultValue + '',
                },
              ]" style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="item.defaultSelectList" placeholder="请选择" tree-default-expand-all @change="item.changeFn">
              </a-tree-select>
            </a-form-item>
            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'multipleSelect'">
              <a-select v-decorator="[
                `${item.value}`,
                {
                  rules:
                    item.rules && item.rules.length ? item.rules : defaultRules,
                  initialValue: item.defaultValue,
                },
              ]" mode="multiple" placeholder="请选择" @change="item.changeFn">
                <a-select-option :value="it.value" v-for="(it, i) in item.defaultSelectList" :key="i">
                  {{ it.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'radio'">
              <a-radio-group v-decorator="[
                `${item.value}`,
                {
                  rules:
                    item.rules && item.rules.length ? item.rules : defaultRules,
                  initialValue: item.defaultValue,
                },
              ]">
                <a-radio :value="it.value" v-for="(it, i) in item.defaultSelectList" :key="i">
                  {{ it.label }}
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'checkbox'">
              <a-checkbox-group v-decorator="[
                `${item.value}`,
                { initialValue: item.defaultValue },
              ]" style="width: 100%">
                <a-row>
                  <a-col>
                    <a-checkbox :value="it.value" v-for="(it, i) in item.defaultSelectList" :key="i">
                      {{ it.label }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'upload'">
              <a-upload :action="item.uploadUrl" :multiple="true" :list-type="item.listType || 'text'"
                :data="{ subdir: item.subdirName }" :file-list="item.value" :showUploadList="{
                  showPreviewIcon: true,
                  showRemoveIcon:
                    item.showRemoveIcon && item.showRemoveIcon === '0'
                      ? false
                      : true,
                }" @change="item.changeFn" @preview="previewFn">
                <a-button v-if="params.type !== 'preview' &&
                  item.value.length < item.limitedLength
                  ">
                  <a-icon type="upload" /> 上传附件
                </a-button>
                <span v-if="params.type === 'preview' &&
                  (!item.value || !item.value.length)
                  ">暂无</span>
              </a-upload>
            </a-form-item>

            <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'desc' && item.value">
              <span>{{ item.value }}</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="modal-footer" v-if="params.type !== 'preview'">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ params.cancelBtnTxt ? params.cancelBtnTxt : l("Cancel") }}
      </a-button>
      <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
        <a-icon type="save" />
        {{ params.okBtnTxt ? params.okBtnTxt : l("Save") }}
      </a-button>
    </div>
  </section>
  <!-- </a-spin> -->
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
// import { AppConsts } from "@/abpPro/AppConsts";
// import { environment } from "@/environments/environment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import moment from 'moment';

export default {
  name: "react-modify",
  mixins: [AppComponentBase, ModalComponentBase],
  data () {
    return {
      spinning: false,
      commonService: null,
      form: this.$form.createForm(this, { name: "rm" }),
      defaultRules: [
        { required: true, message: this.l("ThisFieldIsRequired") },
      ],
      list: [],
      otherParams: {},
    };
  },
  created () {
    this.fullData();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    // console.log(this.params.data)
  },
  mounted () {
    this.$nextTick(async () => {
      this.list = this.params.data;
      if (this.params.initFn && typeof this.params.initFn === "function") {
        this.params.initFn(this.form, this.otherParams);
      }
      if (
        this.params.type === "preview" ||
        this.params.type === "edit" ||
        this.params.type === "others"
      ) {
        // if (this.params.reqParams[0].key === 'id' && this.params.reqParams[0].value) {
        if (
          this.params.type === "others" ||
          (this.params.reqParams[0].key === "id" &&
            this.params.reqParams[0].value)
        ) {
          // this.form.setFieldsValue({...res})
          if (this.params.reqUrl && this.params.reqUrl.getById) {
            try {
              let opts = {
                url: this.params.reqUrl.getById,
                params: {},
              };
              if (this.params.reqParams.length) {
                this.params.reqParams.map((v) => {
                  opts.params[`${v.key}`] = v.value;
                });
              }
              // const res = await this.commonService.get({
              //   url: this.params.reqUrl.getById,
              //   params: {
              //     id:
              //       this.params.reqParams && this.params.reqParams.length
              //         ? this.params.reqParams[0].value
              //         : null,
              //   },
              // });
              const res = await this.commonService.get(opts);
              console.log(res);
              this.params.getItemfn(res, this.form, this.otherParams);
            } catch (error) {
              console.log(error);
              throw new Error(error);
            }
          }
          // else if(this.params.reqUrl.isSetFieldsValue){
          //   this.params.getItemfn(true, this.form, this.otherParams)
          // }
        }
      }
    });
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSubmit () {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          //   console.log(values);
          //   console.log(this.params)
          // let keys =   Object.keys(values);
          // console.log(keys);
          // for
          let paramData = this.params.data.filter(m => m.type == "datePicker" || m.type == "rangeDatePicker");
          for (let i = 0; i < paramData.length; i++) {
            let key = paramData[i].key;
            if (key) {
              if (values[key] == undefined) {
                values[key] = paramData[i].type == "rangeDatePicker" ? [moment(), moment()] : moment();
              }
            }
          }
          console.log(values);
          this.saving = true;
          const res = await this.params.submitfn(values, this.params);
          this.saving = false;
          if (res === "other") {
            this.$notification["success"]({
              message: this.l("SavedSuccessfully"),
            });
          } else if (res === true) {
            this.$notification["success"]({
              message: this.l("SavedSuccessfully"),
            });
            this.success(res);
          } else {
            console.log("fail");
          }
        }
      });
    },
    returnNumerInput (maxLength) {
      let str = "1";
      for (let i = 0; i < maxLength; i++) {
        str += "1";
      }
      // console.log(str)
      // console.log(Number(str) * 9)
      return Number(str) * 9;
    },
    previewFn (e) {
      console.log(e);
      var filePath = e.url;
      //获取最后一个.的位置
      var index = filePath.lastIndexOf(".");
      //获取后缀
      var ext = filePath.substr(index + 1);
      //判断是否是图片
      console.log("该文件是否为图片：" + this.isAssetTypeAnImage(ext));
      var isImage = this.isAssetTypeAnImage(ext);
      // console.log("回调",e);
      if (isImage) {
        var img = new Image();
        img.src = e.url;
        // console.log(img);

        // console.log(img.fileSize);
        img.onload = function () {
          // debugger;
          // console.log(img)
          if (img.fileSize > 0 || (img.width > 0 && img.height > 0)) {
            window.open(img.src, '_blank');
          }
          img = null;
          // else {
          //   var url = img.src.replace(`${AppConsts.uploadBaseUrl}/static`,"http://183.6.120.119:8124")
          //   window.open(url, '_blank');
          // }
          // alert("img is loaded")
        };
        img.onerror = function () {
          // alert("error!")
          var url = img.src.replace(`${AppConsts.uploadBaseUrl}/static`, "http://183.6.46.162:8124")
          window.open(url, '_blank');
          img = null;
        };
      } else {

        window.open(e.url, '_blank');
      }


    },
    isAssetTypeAnImage (ext) {
      return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1;
    }
  },
};
</script>


<style lang="less" scoped>
@import "../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}

.modal-footer {
  border: none;
}

.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }

  .preview-list {
    margin-bottom: 0;
    padding: 0;

    li {
      list-style: none;
      line-height: 30px;
    }
  }
}

.modalform-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 500px;
}
</style>