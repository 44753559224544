import { LayoutBlock, LayoutBlockKeepAlive } from '@/layout';
import { RouteConfig } from 'vue-router';

const unnaturalPersonRouting: RouteConfig[] = [
    {
        path: 'unnaturalperson',
        // tslint:disable-next-line: object-literal-sort-keys
        meta: { title: '非自然人业务' },
        component: LayoutBlockKeepAlive,
        redirect: '/app/unnaturalperson/collectionform',
        children: [
            { path: 'personnel', component: () => import('./personnel/index.vue'), meta: { title: '人员管理' } },
            { path: 'project', component: () => import('./project/project.vue'), meta: { title: '项目管理' } },
            // tslint:disable-next-line: max-line-length
            { path: 'projectpersonel', component: () => import('./projectPersonnel/index.vue'), meta: { title: '项目人员管理' } },
            { path: 'agreementTypeManage', component: () => import('./agreementTypeManage/index.vue'), meta: { title: '协议类型管理' } },
            { path: 'agreementSignManage', component: () => import('./agreementSignManage/index.vue'), meta: { title: '协议签署管理' } },
            { path: 'collectionform', component: () => import('./collectionform/index.vue'), meta: { title: '表单管理' } },
            { path: 'templetemanage', component: () => import('./templeteManage/templeteManage.vue'), meta: { title: '协议模版管理' } },
            { path: 'questionnaire', component: () => import('./questionnaire/index.vue'), meta: { title: '调研问卷' } },
            // tslint:disable-next-line: max-line-length
            { path: 'agreementTypev2', component: () => import('./agreementTypev2/index.vue'), meta: { title: '项目协议管理v2' } },
            // tslint:disable-next-line: max-line-length
            { path: 'wanyooStore', component: () => import('./wanyoostore/wanyooStore.vue'), meta: { title: '网鱼网咖门店管理' } },
            { path: 'personsignmanage', component: () => import('./personsignmanager/index.vue'), meta: { title: '人员签署管理' } },
            { path: 'checksignmanage', component: () => import('./checksignmanager/index.vue'), meta: { title: '签署信息校对' } },
            { path: 'insurancelog', component: () => import('./insurancelog/index.vue'), meta: { title: '保险购买记录' } },
        ],
    },
];

const unnaturalPersonBlockRouting: RouteConfig[] = [
    { path: 'makecontract/:projectagreementId/:projectUserId', component: () => import('./agreementTypev2/makeContract.vue'), meta: { title: '发起签署' } },
    // tslint:disable-next-line: max-line-length
    { path: 'addsignmethodv2/:id/:standard', component: () => import('./agreementTypev2/addSignMethod.vue'), meta: { title: '初始化印章' } },
    // tslint:disable-next-line: max-line-length
    { path: 'addprojectcontract/:id', component: () => import('./agreementTypev2/addProjectContract.vue'), meta: { title: '添加项目协议' } },
    // tslint:disable-next-line: max-line-length
    { path: 'unaddtemplete/:id', component: () => import('./templeteManage/addTemplete.vue'), meta: { title: '添加协议模版' } },
    { path: 'unsignmethod/:id/:standard', component: () => import('./templeteManage/signMethod.vue'), meta: { title: '设置签署方式' } },
    { path: 'unchooseseal/:id/:standard', component: () => import('./templeteManage/chooseSeal.vue'), meta: { title: '选择用章' } },
];

// export default unnaturalPersonRouting;
export { unnaturalPersonRouting, unnaturalPersonBlockRouting };

