<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :hide-row-selection="true"
      :scroll="{ x: 0 }"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitLinkClick="linkClick"
    >
      <a-row :gutter="8" type="flex" align="middle">
        <a-col :span="12">
          <a-input-search
            name="filterText"
            @search="changeFilterText()"
            :placeholder="l('SearchWithThreeDot')"
            enterButton
            v-model="filterText"
          />
        </a-col>
        <a-col :span="8" :offset="4" align="right">
          <a @click="createOrEdit(null, 'create')">
            <a-icon type="plus" /> {{ l("实名认证") }}
          </a>
          <a-divider type="vertical"></a-divider>
          <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
            <a-icon type="reload" />
          </a>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";

export default {
  name: "real-name",
  mixins: [AppComponentBase],
  props: ["id", "tableKey", "reqKey"],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      commonService: null,
      selectedRowKeys: [],
      name: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("实名类型"),
          dataIndex: "sureNameType",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "sureNameType" },
        },
        {
          title: this.l("状态值"),
          dataIndex: "status",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "status" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      actionsType: {},
      getDataParams: [],
      changeOpt: [],
    };
  },
  computed: {},
  created() {
    let _this = this;
    console.log(this.reqKey);
    if (this.reqKey == "OrganizationRealName") {
      this.columns.unshift({
        title: this.l("授权自动签"),
        dataIndex: "isAutoSign",
        sorter: false,
        type: "tag",
        align: "center",
        customRender: function (value, record, index) {
          console.log("aaaa", value, record, index);

          let child = _this.$createElement("a-button", {
            domProps: {
              innerHTML: "未授权",
            },
            attrs: { type: "danger" },
            on: {
              click: function () {
                _this.getAutoSignUrl(record, "create");
              },
            },
          });
          let obj = {
            children: child,
          };
          return obj;
        },
        scopedSlots: {
          customRender: "",
        },
      });
    }
    if (this.reqKey === "CustomerRealName") {
      this.columns.unshift({
        title: this.l("所属客户"),
        dataIndex: "customerName",
        sorter: false,
        align: "center",
        scopedSlots: { customRender: "customerName" },
      });
    } else {
      this.columns.unshift({
        title: this.l("所属客户"),
        dataIndex: "customerId",
        sorter: false,
        align: "center",
        scopedSlots: { customRender: "customerId" },
      });
    }

    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
  },
  methods: {
    // 拉取数据
    async getData(opt, url) {
      this.spinning = true;
      let params = {
        // customerId: this.id,
        filterText: this.filterText,
        sorting: this.request.sorting,
        skipCount: this.request.skipCount,
        maxResultCount: this.request.maxResultCount,
      };
      if (opt && opt.length) {
        if (!this.getDataParams.length) this.getDataParams = opt;
        opt.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      } else if (this.getDataParams && this.getDataParams.length) {
        this.getDataParams.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      }
      this.changeOpt = opt;
      try {
        let res = await this.commonService.get({
          url: url ? url : `/api/services/app/${this.reqKey}/GetPaged`,
          params: params,
        });
        console.log();
        this.tableData = res.items.map((o) => {
          return {
            ...o,
            sureNameType: o.sureNameType === "Fdd" || "FDD" ? "法大大" : "-",
            status: this.getStatus(o.status),
            isAutoSign: o.isAutoSign ? "已授权" : "未授权",
            isAutoSignColor: o.isAutoSign ? "#87d068" : "#f50",
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async changeFilterText(value) {
      this.request.skipCount = 0;
      this.filterText = value;
      await this.getData(this.changeOpt);
    },
    getStatus(type) {
      switch (type) {
        case 0:
          return "未认证";
          break;
        case 1:
          return "管理员资料已提交";
          break;
        case 2:
          return "企业基本资料(没有申请表)已提交";
          break;
        case 3:
          return "已提交待审核";
          break;
        case 4:
          return "审核通过";
          break;
        case 5:
          return "审核不通过";
          break;
        case 6:
          return "人工初审通过";
          break;
        default:
          return "-";
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData(this.changeOpt);
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    linkClick(newV) {
      const { item, index } = newV;
      console.log(item);
      if (item.isAutoSign === "未授权") {
        this.getAutoSignUrl(item, "create");
      }
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "provide",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("手动刷新"),
              icon: "edit",
              fn: (data) => {
                _this._refRealNameStatus(data.id);
              },
            },
             {
              granted: true,
              name: this.l("获取授权自动签"),
              icon: "edit",
              fn: (data) => {
                   _this.getAutoSignUrl(data, "create");
              },
            },
          ],
          
        },
      };
      this.actionsType = obj;
    },
    async _refRealNameStatus(id) {
      this.spinning = true;
      try {
        const res = await this.commonService.post({
          url: `/api/services/app/${this.reqKey}/RefRealNameStatus`,
          params: { id: id },
        });
        this.$notification["success"]({
          message: this.l("刷新成功"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 增加修改查看
    createOrEdit(item, type) {
      const list = [
        {
          label: "获取实名地址",
          value: "sureNameType",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择实名的第三方" }],
          defaultValue: "4",
          defaultSelectList: [{ label: "法大大", value: "4" }],
          span: 20,
          changeFn: () => {},
        },
        {
          label: "地址",
          value: "",
          type: "desc",
          disabled: false,
          required: false,
          rules: [],
          span: 20,
          changeFn: () => {},
        },
      ];
      let reqParams = [];
      // 后续接口不需要id
      // if (item && item.id) reqParams.push({key: 'id', value: item.id})
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [
              ...reqParams,
              {
                key: `${
                  this.reqKey === "CustomerRealName"
                    ? "customerId"
                    : "organizationId"
                }`,
                value: this.id,
              },
            ],
            reqUrl: {
              // getById: '',
              save: `/api/services/app/${this.reqKey}/GetRealNameAddress`,
            },
            otherParams: {},
            okBtnTxt: "确定",
            cancelBtnTxt: "关闭",
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({ ...res });
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);
              // console.log(values)
              try {
                let res = await this.commonService.get({
                  url: queryParams.reqUrl.save,
                  params: { ...values },
                });
                list[1].value = res;
                return "other";
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "600px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    getAutoSignUrl(item, type) {
      const list = [
        {
          label: "授权自动签地址",
          value: "sureNameType",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择实名的第三方" }],
          defaultValue: "4",
          defaultSelectList: [{ label: "法大大", value: "4" }],
          span: 20,
          changeFn: () => {},
        },
        {
          label: "地址",
          value: "",
          type: "desc",
          disabled: false,
          required: false,
          rules: [],
          span: 20,
          changeFn: () => {},
        },
      ];
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqUrl: {
              save: `/api/services/app/OrganizationRealName/GetCompanyAutoSignUrl`,
              params: { id: item.id },
            },
            otherParams: {},
            okBtnTxt: "确定",
            cancelBtnTxt: "关闭",
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({ ...res });
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              if (values.sureNameType == "4") {
                this.spinning = false;
                let options = {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json-patch+json",
                  },
                  url: this.$apiUrl + queryParams.reqUrl.save,
                  params: queryParams.reqUrl.params,
                };
                this.$api
                  .request(options)
                  .then((res) => {
                    if (res.status == 200) {
                      list[1].value = "授权自动签地址：" + res.data;
                    }
                  })
                  .catch((e) => {
                    console.error(e);
                  })
                  .finally(() => {
                    this.spinning = false;
                  });
              }
            },
          },
        },
        {
          isChange: true,
          width: "640px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-edit,
  .table-delete {
    margin-left: 3px;
    i {
      padding: 0 2px;
    }
  }
}

.opeattion-container {
  margin: 20px 0;
}

.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
