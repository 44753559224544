import Vue from 'vue';
import Vuex from 'vuex';
import { IRootState } from './interfaces';

import getters from './getters';

import {
  layoutStore,
  abpStore,
  customsFormStore
} from './modules';

Vue.use(Vuex);

export default new Vuex.Store<IRootState>({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    layoutStore: layoutStore,
    abpStore: abpStore,
    customsFormStore:customsFormStore
  },
  getters: getters
});
