/*
 * author zr
 * date 2019-11-20
 */
import ZrFormItem from "./index.vue";

ZrFormItem.install = function(Vue) {
  Vue.component(ZrFormItem.name, ZrFormItem);
};

export default ZrFormItem;
