import { RouteConfig } from "vue-router";
import { LayoutBlock, LayoutBlockKeepAlive } from "@/layout";
import customizeFromRouting from "@/app/systemManagement/customize-form/customizeForm.routing";

const systemManagementRouting: RouteConfig[] = [
  {
    path: "systemManagement",
    meta: { title: "系统" },
    component: LayoutBlockKeepAlive,
    redirect: "/app/systemManagement/dataItem",
    children: [
      {
        path: "dataItem",
        component: () => import("./dataItem/index.vue"),
        meta: { title: "dataItem" },
        children: [
          {
            path: "form",
            component: () => import("./dataItem/form.vue"),
            meta: { title: "dataItemForm" },
          },
        ],
      },
      {
        path: "sysInterface",
        component: () => import("./sysInterface/index.vue"),
        meta: { title: "sysInterface" },
        children: [
          {
            path: "form",
            component: () => import("./sysInterface/form.vue"),
            meta: { title: "sysInterfaceForm" },
          },
        ],
      },
      {
        path: "dataSourceDic",
        component: () => import("./dataSourceDic/dataSourceDic.vue"),
        meta: { title: "数据库字典" },
      },
      {
        path: "er",
        component: () => import("./er/index.vue"),
        meta: { title: "er" },
      },
    ],
  },
  ...customizeFromRouting,
];

export default systemManagementRouting;
