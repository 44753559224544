import { RouteConfig } from 'vue-router';
import { LayoutBlock, LayoutDefault, LayoutBlockKeepAlive } from '@/layout';

const agreementRouting: RouteConfig[] = [
    {
        path: 'agreement',
        meta: { title: '电子协议' },
        component: LayoutBlockKeepAlive,
        redirect: '/app/agreement/category',
        children: [
            { path: 'agreementCategoryFields', component: () => import('./agreement-category-fields/agreement-category-fields.vue') },
            { path: 'generalFields', component: () => import('./general-fields/general-fields.vue') },
            { path: 'category', component: () => import('./category/category.vue') },
            { path: 'seal', component: () => import('./seal/seal.vue') },
        ]
    },
];

const agreementBlockRouting: RouteConfig[] = [
    {
        path: 'agreementTemplete/:id',
        component: () => import('./category/templete/templete.vue'),
        // meta: {title: "添加/修改协议模版"},
    },
    {
        path: 'agreementDetail/:id',
        redirect: '/app/fs/agreementDetail/123/sign',
        component: () => import('./detail/detail.vue'),
        children: [
            { path: 'sign', component: () => import('./detail/sign/sign.vue') },
            { path: 'templete', component: () => import('./detail/templete/templete.vue') },
            { path: 'archiveSettings', component: () => import('./detail/archive-settings/archive-settings.vue') },
        ]
    },
];

export { agreementRouting, agreementBlockRouting };
