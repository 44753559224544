import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';


const customizeFromRouting: RouteConfig[] = [
    {
        path: 'ZrCustomizeForm',
        meta: { title: "业务表单" },
        component:()=>import('./index.vue'),
        redirect: '/app/ZrCustomizeForm/index',
        children: [
            { path: 'designer', component: () => import('./designer/designer.vue'), meta: { title: "设计器" } },
            { path: 'index', component: () => import('./index.vue'), meta: { title: "业务表单管理" } },
        ]
    },
];

export default customizeFromRouting;
