import { abpService } from "@/shared/abp";
import { IAclService } from "@/shared/common";

class PermissionService implements IAclService {
  can(acl: string): boolean {
    if (acl == "Pages.Company.Query") {
      console.log(acl);
      console.log(abpService.abp.auth.allPermissions);  
      console.log(abpService.abp.auth.isGranted(acl));
    }

    return abpService.abp.auth.isGranted(acl);
  }
}

const permissionService = new PermissionService();
export default permissionService;
