/*
 * @Author: hzk
 * @Date: 2020-11-30 15:01:31
 * @LastEditors: hzk
 * @LastEditTime: 2021-01-15 13:52:41
 */
import 'rxjs/add/operator/finally';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  RolesListDto,
} from './model'

import { SwaggerException } from "@/shared/service-proxies/model";

import { CommonProcess } from "@/shared/common-process";
const cp = new CommonProcess()

import moment from "moment";

export class CommonServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 获取当前用户的FieldRole，包含当前用户是否拥有该字段权限
   * @return Success
   */
  getUserFieldRole(): Promise<RolesListDto> {
    let url_ = this.baseUrl + "/api/services/app/Role/GetUserFieldRole";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetUserFieldRole(_response);
    });
  }
  protected processGetUserFieldRole(response: AxiosResponse): Promise<RolesListDto> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      // result200 = RolesListDto.fromJS(resultData200);
      // return result200;
      // 不需要任何转换直接返回
      return resultData200
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<RolesListDto>(<any>null);
  }

  get(opts: any, headers: any): Promise<any> {
    let url_ = this.baseUrl + opts.url;
    url_ = url_.replace(/[?&]$/, "");
    headers = headers || {};
    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain",
        ...headers
      },
      params: opts.params,
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  post(opts: any): Promise<any> {
    let url_ = this.baseUrl + opts.url;
    url_ = url_.replace(/[?&]$/, "");
    let options_ = <AxiosRequestConfig>{
      method: "POST",
      data: opts.params,
      url: url_,
      headers: {
        "Accept": "text/plain"
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  put(opts: any): Promise<any> {
    let url_ = this.baseUrl + opts.url;
    url_ = url_.replace(/[?&]$/, "");
    let options_ = <AxiosRequestConfig>{
      method: "PUT",
      data: opts.params,
      url: url_,
      headers: {
        "Accept": "text/plain"
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  delete(opts: any): Promise<void> {
    let url_ = this.baseUrl + `${opts.url}?id=${opts.params}`;
    if (opts.params === null) {
      throw new Error("The parameter 'params' cannot be null.");
    }
    url_ = url_.replace(/[?&]$/, "");
    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }


}


function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}


export function initVerifiedWay(type: string) {
  switch (type) {
    case 'ThreeElements':
      return '纯三要素'
    case 'FourElements':
      return '纯四要素'
    case 'FaceRecognition':
      return '人脸识别'
    case 'ThreeElementsFaceRecognition':
      return '三要素+人脸'
    case 'FourElementsFaceRecognition':
      return '四要素+人脸'
  }
}

export function ProjectBusinessTypeConvert(type: string) {

  switch (type) {
    case 'YG':
      return 1;
    case 'WB':
      return 2;
      defualt:
      return '未识别'
  }
}

export function initProjectBusinessType(type: string) {

  switch (type) {
    case 'YG':
      return '灵活用工'
    case 'WB':
      return '新经济个体'
      defualt:
      return '未识别'
  }
}

export function initProjectType(type: string) {
  switch (type) {
    case 'RenLiWaiBao':
      return '人力外包'
      // return '新经济个体'
    case 'XiangMuZiXun':
      return '项目咨询'
    case 'TuiGuangFuWu':
      return '推广服务'
    case 'LuYanHuiZhan':
      return '路演会展'
    case 'XinXiJiShu':
      return '信息技术'
    case 'EconomicEntity':
      return '新经济个体'
    default:
      return ''
  }
}

export function initProjectStatus(type: string) {
  switch (type) {
    case 'Processing':
      return '进行中'
    case 'NoStart':
      return '未开始'
    case 'Ended':
      return '执行结束'
    default:
      return ''
  }
}

export function initFormType(type: any) {
  switch (type) {
    case 0:
      return "自然人";
    case 1:
      return "非自然人类型";
    case 2:
      return "执行记录";
    case 3:
      return "系统表单";
    default:
      return ''
  }
}

export function initLevel(t: any) {
  switch (t) {
    case 0:
      return "普通";
      break;
    case 1:
      return "重要";
      break;
    case 2:
      return "紧急";
      break;
    default:
      return "普通";
      break;
  }
}

export function initEnableMark(record, text, categoryId) {
  if (record.taskType == 4) {
    if (record.isFinished == 0) {
      if (record.isUrge == "1" && categoryId == "2") {
        return '催办加急';
      }
      return '运行中';
    } else {
      return '结束';
    }
  }
  if (record.isFinished == 0) {
    if (text == 1) {
      if (record.isUrge == "1" && categoryId == "2") {
        return '催办加急';
      }
      return '运行中';
    } else if (text == 2) {
      return '草稿';
    } else {
      return '作废';
    }
  } else {
    return '结束';
  }
}

export function initInvoice(t) {
  switch (t) {
    case '1':
      return "增值税普通发票";
    case '2':
      return "增值税专用发票";
    default:
      return ''
  }
}

export function ymdhmsFormat(row, column) {
  let date = row[column.property];
  if (date === undefined || date === null) {
    return ''
  }
  return moment(date).format("YYYY-MM-DD HH:mm:ss")
}


export function initTaskStatus(text) {
  if (text == "Auditing") {
    return "审核中";
  }
  if (text == "WaitSign") {
    return "待签约";
  }
  if (text == "TaskRunning") {
    return "任务进行中";
  }
  if (text == "Ended") {
    return "任务已经完结";
  }
  if (text == "Refuse") {
    return "拒绝";
  }
  if (text == "Termination") {
    return "任务终止";
  }
  return "";
}

export function initFieldLibOptions(row, text) {
  switch (row.fieldType) {
    case 'ddselectfield':
      if (text) {
        var html = text.trim().replace('[', '').replace(']', '');
        html = html.replace('","', ',');
        html = html.replace('"', '');
        return html;
      }
      break;
    case 'ddmultiselectfield':
    case 'radioselectfield':
      if (text) {
        var array = JSON.parse(text);
        var val = '';
        if (array) {
          array.forEach(item => {
            val += item.text + ',';
          });
          return val.substring(0, val.length - 1);
        }
      }
      break;
  }
  return '';
}

export function initSealType(text) {
  if (text == 1) {
    return "证明类";
  }
  if (text == 2) {
    return "法大大";
  }
  return "-";
}

export function initColumnsWidth(twWidth, columnsLength, actionWidth, actionsType) {
  if (columnsLength > 0) {
    if (actionsType && actionsType.isShow) {
      twWidth = twWidth - Number(actionWidth ? actionWidth : 211)
    }
    let width = twWidth / columnsLength - 1
    return width > 160 ? `${width}` : '160'
  } else {
    return '160'
  }
}