<template>
  <div class="list-table">
    <div class="my-md" v-if="!hideRowSelection">
      <a-alert :type="'info'" :showIcon="true">
        <template slot="message">
          <span v-html="l('GridSelectedXItemsTips', selectedRowKeys.length)"></span>
          <a @click="clearData()" class="ml-md">{{ l("ClearEmpty") }}</a>
          <a-divider type="vertical"></a-divider>
          <a @click="refreshData()">{{ l("Refresh") }}</a>
        </template>
      </a-alert>
    </div>
    <!-- table -->
    <a-row>
      <a-table
        class="list-table"
        @change="sortData"
        :pagination="false"
        :rowSelection="hideRowSelection ? null : initRowSelection()"
        :scroll="scroll ? scroll : {x: 1000}"
        :columns="columns && columns.length ? columns : myColumns"
        :rowKey="(tableDatas) => tableDatas.id"
        :data-source="tableData"
      >
<span slot="pic" slot-scope="text, record">
        <img style="width:150px;heigth:150px" :src="record.images" />
      </span>
        <!-- action list -->
        <span
          slot="actions"
          slot-scope="text, record"
          v-if="actionsType.isShow"
        >
          <!-- preview -->
          <a class="table-edit" v-if="actionsType.fns.check && actionsType.fns.check.granted" @click="preview(record)">
            <a-icon type="unordered-list" />{{ actionsType.fns.check.name }}
          </a>
          <!-- edit -->
          <a class="table-edit" v-if="actionsType.fns.edit && actionsType.fns.edit.granted" @click="edit(record)">
            <a-icon type="edit" />{{ actionsType.fns.edit.name }}
          </a>
          <!-- create -->
          <a class="table-plus" v-if="actionsType.fns.create && actionsType.fns.create.granted" @click="create(record)">
            <a-icon type="plus" />{{ actionsType.fns.create.name }}
          </a>
          <!-- others -->
          <a class="table-plus" v-if="actionsType.fns.other && actionsType.fns.other.granted" @click="other(record)">
            <a-icon type="profile" />{{ actionsType.fns.other.name }}
          </a>
          <!-- more others -->
          <a class="table-plus" v-if="actionsType.fns.moreOther && actionsType.fns.moreOther.granted" @click="moreOther(record)">
            <a-icon type="profile" />{{ actionsType.fns.moreOther.name }}
          </a>
          <!-- delete -->
          <a-popconfirm
            placement="top"
            class="table-delete"
            v-if="actionsType.fns.delete && actionsType.fns.delete.granted"
            :okText="l('Ok')"
            :cancelText="l('Cancel')"
            @confirm="deleteItem(record)"
          >
            <template slot="title">
              {{ l("ConfirmDeleteWarningMessage") }}
            </template>
            <a class="table-delete">
              <a-icon type="delete" />{{ actionsType.fns.delete.name }}
            </a>
          </a-popconfirm>
        </span>

      </a-table>
      <a-pagination
        class="pagination"
        size="middle"
        :total="totalItems"
        showSizeChanger
        showQuickJumper
        :showTotal="showTotal"
        @change="onPageChange"
        @showSizeChange="showSizeChange"
      />
    </a-row>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  mixins: [AppComponentBase],
  name: "list-table",
  props: [
    "tableData", // 表格数据
    "totalItems", // 总数
    "showTotal", // 分页组件所需数据
    "actionsType", // 显示那些按钮
    "tableKey",
    "scroll",
    "columns",
    "hideRowSelection", // 多选
  ],
  data() {
    return {
      selectedRowKeys: [], // 所选项index []
      selectedRows: [], // 所选项item []
      curPage: "",
      myColumns: [],
      rowSelection: {},
      commonService: null,
    };
  },
  computed: {},
  created() {
    if (!this.columns || !this.columns.length) {
      this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
      this._getFieldRoleByRoleId()
      // let lockUserField = JSON.parse(localStorage.userFieldRole || "[]");
      // if (lockUserField.length == 0) {
      //   this._getFieldRoleByRoleId();
      // } else {
      //   this.initColumns();
      // }
    }
  },
  mounted() {
  },
  methods: {
    initRowSelection() {
      const rowSelection = {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      }
      return rowSelection
    },
    clearData() {
      this.selectedRowKeys = [];
    },
    refreshData() {
      this.$emit("emitRefreshData");
    },
    sortData(pagination, filters, sorter) {
      let order;
      if (sorter && sorter.order === "ascend") {
        order = "ASC";
      } else if (sorter && sorter.order === "descend") {
        order = "DESC";
      } else {
        order = "";
      }
      this.$emit("emitSortData", { columnKey: sorter.columnKey, order: order });
    },
    preview(item) {
      this.$emit("emitPreview", { item: item });
    },
    edit(item) {
      this.$emit("emitEdit", { item: item });
    },
    create(item) {
      this.$emit("emitCreate", { item: item });
    },
    deleteItem(item) {
      this.$emit("emitDelete", { item: item });
    },
    other(item) {
      this.$emit("emitOther", { item: item });
    },
    moreOther(item) {
      this.$emit("emitMoreOther", { item: item });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.$emit("emitSelectChange", {
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
      });
    },
    onPageChange(page, pageSize) {
      console.log('onPageChange')
      this.$emit("emitOnPageChange", { page: page, skipCount: pageSize });
    },
    showSizeChange(current, size) {
      this.$emit("emitShowSizeChange", {
        curPage: current,
        maxResultCount: size,
      });
    },
    initColumns() {
      // console.log(this.tableKey);
      let userFieldRole = JSON.parse(localStorage.userFieldRole);
      let roleFocus = userFieldRole.filter((value, index) => {
        return value.key == this.tableKey;
      });
      let columnsFocus = [];
      if (roleFocus && roleFocus.length > 0) {
        columnsFocus = roleFocus[0].items.filter((value, index) => {
          return value.isCheck === true;
        });
      }
      // console.log(JSON.stringify(columnsFocus))
      let columns = [];
      console.log(columnsFocus)
      columnsFocus.map((item) => {
        let myDataIndex;
        let sorterFn = (a, b) => a.length - b.length;
        if (item.key !== "Id" && item.isCheck === true) {
          myDataIndex = item.key.replace(item.key[0], item.key[0].toLowerCase());
          columns.push({
            title: item.name,
            dataIndex: myDataIndex,
            sorter: true,
            align: "center",
            width: "150",
            scopedSlots: item.key=='Images'? { customRender: 'pic' }: { customRender: item.key },
            sorter: sorterFn,
          });
        }
      });
      console.log(columns)
      if (columns.length) {
        columns.push({
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          fixed: 'right',
          align: "center",
          scopedSlots: { customRender: "actions" },
        });
      }

      this.myColumns = columns;
    },
    _getFieldRoleByRoleId() {
      this.spinning = true;
      this.commonService
        .getUserFieldRole()
        .finally(() => {
          this.spinning = false;
        })
        .then((result) => {
          localStorage.userFieldRole = JSON.stringify(result);
          this.initColumns();
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./style/index.less";
.pagination {
  margin: 10px auto;
  text-align: right;
}
.table-plus,
.table-delete {
  margin-left: 5px;
}
</style>
