import { RouteConfig } from "vue-router";
import { LayoutDefault, LayoutBlock } from "@/layout";
import { mainRouting } from "./main";
import { demoRouting } from "./demo";
import { adminRouting } from "./admin";
import { wechatRouting } from "./wechat";
import { otherRouting } from "./other";
import { websiteRouting } from "./website";
import { bloggingRouting } from "./blogging";
import { lingGongRouting, lingGongBlockRouting } from "./linggong";
import { unnaturalPersonRouting, unnaturalPersonBlockRouting } from "./unnaturalperson";
import customizeFromRouting from "./systemManagement/customize-form/customizeForm.routing";
import { codeRouting } from "./codesmart";
import { agreementRouting, agreementBlockRouting } from "./agreement";
import projectRouting from "./project/projectRouting";
import systemManagementRouting from "@/app/systemManagement/systemManagement.routing";
import workflowRouting from "@/app/workflow/workflow.routing";
import trainRouting from "@/app/train/train.route";
import financialRouting from "@/app/financial/financialRouting";
import datacenterRouting from "@/app/datacenter/datacenterRouting";
import { from } from "rxjs";

const appRouting: RouteConfig[] = [
  {
    path: "/app",
    component: LayoutDefault,
    redirect: "/app/main",
    children: [
      ...otherRouting,
      ...wechatRouting,
      ...mainRouting,
      ...demoRouting,
      ...adminRouting,
      ...websiteRouting,
      ...bloggingRouting,
      ...lingGongRouting,
      ...agreementRouting,
      ...projectRouting,
      ...codeRouting,
      ...systemManagementRouting,
      ...workflowRouting,
      ...unnaturalPersonRouting,
      ...unnaturalPersonBlockRouting,
      ...trainRouting,
      ...financialRouting,
      ...datacenterRouting
    ],
  },
  {
    path: "/app/fs",
    component: LayoutBlock,
    children: [...agreementBlockRouting, ...unnaturalPersonBlockRouting, ...lingGongBlockRouting],
  },
];

export default appRouting;
