<template>
<div :class="[themeClass]">
    <div :class="{'alain-pro__collapsed':collapsed}" class="ant-layout ant-layout-has-sider">

        <a-layout-sider :collapsed="collapsed" :width="256" class="alain-pro__sider alain-pro__sider-fixed">

            <sidebar class="alain-pro__aside"></sidebar>

        </a-layout-sider>

        <div class="ant-layout ant-pro-content">

            <layout-header></layout-header>

            <a-layout-content class="layout-window">
                <reuse-tab class="layout-tab" v-if="reuseTab"></reuse-tab>
                <!-- <no-permission v-if="isNoPermisson" /> -->

                <a-layout-content class="layout-content" style="margin-top:10px;padding: 0 10px;">
                    <router-view />
                </a-layout-content>
            </a-layout-content>

        </div>

    </div>
</div>
</template>

<script>
import {
    ReuseTab
} from '@/components'
import {
    LayoutHeader
} from "./header";
import {
    Sidebar
} from './sidebar';
import {
    layoutService,
    // menuService,
} from '@/shared/common';
import {
    SignalRAspNetCoreHelper
} from '@/shared/helpers';
import {
    abpSignalrService
} from '@/shared/auth';
import {
    abpService
} from '@/shared/abp';
// import { AppComponentBase } from "@/shared/component-base";
// import NoPermission from '@/components/noPermission/noPermission.vue';

export default {
    // mixins: [AppComponentBase],
    name: "layout-default",
    components: {
        LayoutHeader,
        ReuseTab,
        Sidebar,
        // NoPermission,
    },
    data() {
        return {
            isNoPermisson: false,
        }
    },
    computed: {
        collapsed() {
            return layoutService.data.collapsed;
        },
        theme() {
            return layoutService.data.theme;
        },
        themeClass() {
            return `alain-pro__${this.theme}`;
        },
        reuseTab() {
            return layoutService.data.reuseTab;
        },
    },
    created() {
        // let path = this.$route.path
        // let menuItem = menuService.findByLink(path);
        // if (!this.isGranted(menuItem.acl)) {
        //     this.isNoPermisson = true
        // } else {
        //     this.isNoPermisson = false
        // }
    },
    mounted() {
        SignalRAspNetCoreHelper.initSignalR(() => {
            if (abpService.loginInfo.user) {
                abpSignalrService.init();
            }
        });
    },
    watch: {
        // $route(to, from) {
        //     console.log(this.$route)
        //     // console.log(to)
        //     let path = to.path
        //     let menuItem = menuService.findByLink(path);
        //     console.log('menuItem:', menuItem)
        //     console.log('权限:', this.isGranted(menuItem.acl))
        //     if (!this.isGranted(menuItem.acl)) {
        //         this.isNoPermisson = true
        //     } else {
        //         this.isNoPermisson = false
        //     }
        // }
    }
}
</script>

<style lang="less" scoped>
@import "./layout-default.less";
</style>
