import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '../layout/block/index';
const bulletinboardRouting: RouteConfig[] = [
  {
    path: '/bulletin',
    redirect: '/bulletin/board',
    component: LayoutBlock,
    children: [
      {
        path: 'board',
        name: 'board',
        component: () =>
          import(/* webpackChunkName: "account" */ './index.vue'),
      },
    ],
  },
];

export default bulletinboardRouting;
