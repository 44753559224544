import { RouteConfig } from 'vue-router';
import { LayoutBlock, LayoutBlockKeepAlive } from '@/layout';

const financialRouting: RouteConfig[] = [
    {
        path: 'finanical',
        meta: { title: "�������" },
        component: LayoutBlockKeepAlive,
        redirect: '/app/finanical/issue',
        children: [
            { path: 'issue', component: () => import('./issue/list.vue'), meta: { title: "��˷���" } },
            { path: 'statistical', component: () => import('./statistical/list.vue'), meta: { title: "���Ż���" } },
            /*{ path: 'detail', component: () => import('./detail/list.vue'), meta: { title: "发放明细" } },*/
            { path: 'receipt', component: () => import('./receipt/list.vue'), meta: { title: "��Ʊ����" } },
            { path: 'bill', component: () => import('./bill/bill.vue'), meta: { title: "账单预览" } },
            { path: 'viewbill', component: () => import('./viewbill/viewbill.vue'), meta: { title: "账单" } },
            { path: 'refund', component: () => import('./refund/refund.vue'), meta: { title: "财务退款" } },

        ]
    },
];

export default financialRouting;
