<template>
    <router-view/>
</template>

<script>

    export default {
        mixins: [],
        data() {
            return {}
        },
        mounted() {

        }
    }
</script>
<style lang="less">
    // 引入字体样式
    @import "./styles/font/iconfont.css";

    #app {
        height: 100%;
    }
</style>
