var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    [
      'input',
      'textarea',
      'date',
      'time',
      'number',
      'radio',
      'checkbox',
      'select',
      'rate',
      'switch',
      'slider',
      'uploadImg',
      'uploadFile',
      'cascader',
      'treeSelect'
    ].includes(_vm.record.type)
  )?_c('a-form-model-item',{attrs:{"prop":("domains." + _vm.index + "." + (_vm.record.model)),"rules":_vm.record.rules}},[(_vm.record.type === 'input')?_c('a-input',{style:(("width:" + (_vm.record.options.width))),attrs:{"disabled":_vm.record.options.disabled || _vm.parentDisabled,"placeholder":_vm.record.options.placeholder,"type":_vm.record.options.type,"allowClear":_vm.record.options.clearable,"maxLength":_vm.record.options.maxLength,"value":_vm.value},on:{"change":function($event){return _vm.handleChange($event.target.value)}}}):(_vm.record.type === 'textarea')?_c('a-textarea',{style:(("width:" + (_vm.record.options.width))),attrs:{"autoSize":{
      minRows: _vm.record.options.minRows,
      maxRows: _vm.record.options.maxRows
    },"disabled":_vm.record.options.disabled || _vm.parentDisabled,"placeholder":_vm.record.options.placeholder,"allowClear":_vm.record.options.clearable,"maxLength":_vm.record.options.maxLength,"rows":4,"value":_vm.value},on:{"change":function($event){return _vm.handleChange($event.target.value)}}}):(_vm.record.type === 'date')?_c('KDatePicker',{attrs:{"parentDisabled":_vm.parentDisabled,"record":_vm.record,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'time')?_c('KTimePicker',{attrs:{"parentDisabled":_vm.parentDisabled,"record":_vm.record,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'number')?_c('a-input-number',{style:(("width:" + (_vm.record.options.width))),attrs:{"min":_vm.record.options.min || _vm.record.options.min === 0
        ? _vm.record.options.min
        : -Infinity,"max":_vm.record.options.max || _vm.record.options.max === 0
        ? _vm.record.options.max
        : Infinity,"precision":_vm.record.options.precision > 50 ||
      (!_vm.record.options.precision && _vm.record.options.precision !== 0)
        ? null
        : _vm.record.options.precision,"disabled":_vm.record.options.disabled || _vm.parentDisabled,"step":_vm.record.options.step,"placeholder":_vm.record.options.placeholder,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'radio')?_c('a-radio-group',{attrs:{"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.record.options.disabled || _vm.parentDisabled,"placeholder":_vm.record.options.placeholder,"value":_vm.value},on:{"change":function($event){return _vm.handleChange($event.target.value)}}}):(_vm.record.type === 'checkbox')?_c('a-checkbox-group',{attrs:{"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.record.options.disabled || _vm.parentDisabled,"placeholder":_vm.record.options.placeholder,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'rate')?_c('a-rate',{attrs:{"count":_vm.record.options.max,"disabled":_vm.record.options.disabled || _vm.parentDisabled,"placeholder":_vm.record.options.placeholder,"allowHalf":_vm.record.options.allowHalf,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'select')?_c('a-select',{style:(("width:" + (_vm.record.options.width))),attrs:{"placeholder":_vm.record.options.placeholder,"showSearch":_vm.record.options.filterable,"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.record.options.disabled || _vm.parentDisabled,"allowClear":_vm.record.options.clearable,"mode":_vm.record.options.multiple ? 'multiple' : '',"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'switch')?_c('a-switch',{attrs:{"disabled":_vm.record.options.disabled || _vm.parentDisabled,"checked":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'slider')?_c('div',{staticClass:"slider-box",style:(("width:" + (_vm.record.options.width)))},[_c('div',{staticClass:"slider"},[_c('a-slider',{attrs:{"disabled":_vm.record.options.disabled || _vm.parentDisabled,"min":_vm.record.options.min,"max":_vm.record.options.max,"step":_vm.record.options.step,"value":_vm.value},on:{"change":_vm.handleChange}})],1),(_vm.record.options.showInput)?_c('div',{staticClass:"number"},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.record.options.disabled || _vm.parentDisabled,"min":_vm.record.options.min,"max":_vm.record.options.max,"step":_vm.record.options.step,"value":_vm.value},on:{"change":_vm.handleChange}})],1):_vm._e()]):(_vm.record.type === 'uploadImg')?_c('UploadImg',{style:(("width:" + (_vm.record.options.width))),attrs:{"parentDisabled":_vm.parentDisabled,"record":_vm.record,"config":_vm.config,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'uploadFile')?_c('UploadFile',{style:(("width:" + (_vm.record.options.width))),attrs:{"parentDisabled":_vm.parentDisabled,"dynamicData":_vm.dynamicData,"config":_vm.config,"record":_vm.record,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'treeSelect')?_c('a-tree-select',{style:(("width:" + (_vm.record.options.width))),attrs:{"placeholder":_vm.record.options.placeholder,"multiple":_vm.record.options.multiple,"showSearch":_vm.record.options.showSearch,"treeCheckable":_vm.record.options.treeCheckable,"treeData":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.record.options.disabled || _vm.parentDisabled,"allowClear":_vm.record.options.clearable,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'cascader')?_c('a-cascader',{style:(("width:" + (_vm.record.options.width))),attrs:{"placeholder":_vm.record.options.placeholder,"showSearch":_vm.record.options.showSearch,"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.record.options.disabled || _vm.parentDisabled,"allowClear":_vm.record.options.clearable,"value":_vm.value},on:{"change":_vm.handleChange}}):_vm._e()],1):(_vm.record.type === 'text')?_c('a-form-model-item',[_c('div',{style:({ textAlign: _vm.record.options.textAlign })},[_c('label',{class:{ 'ant-form-item-required': _vm.record.options.showRequiredMark },domProps:{"textContent":_vm._s(_vm.record.label)}})])]):(_vm.record.type === 'html')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.record.options.defaultValue)}}):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }