/*
 * author zr
 * date 2019-11-20
 */
import ZrFormBuild from "./index.vue";

ZrFormBuild.install = function(Vue) {
  Vue.component(ZrFormBuild.name, ZrFormBuild);
};

export default ZrFormBuild;
