import {IRootState} from '@/shared/store/interfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';

 export  interface  ICustomsFormState {
      formJson:any
 }
/**
 * mutations
 */
const mutations:MutationTree<ICustomsFormState>  = {
    SET_FORM_JSON:(state, val)=> {
        state.formJson = val;
    }
};

const actions:ActionTree<ICustomsFormState,IRootState>  = {
    UpdateFormJson:({ commit }, formJson)=>{
        commit("SET_FORM_JSON", formJson);
    }
};


/**
 * getters
 */
const getters:GetterTree<ICustomsFormState, IRootState>  = {
    get: (state:ICustomsFormState):ICustomsFormState => {
        return state.formJson;
    },
};


const state:ICustomsFormState = {
     formJson:""
}


const customsFormStore:Module<ICustomsFormState,IRootState> = {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};

export default customsFormStore;
