export  default {
     clone(obj){
             return  Object.create(
                 Object.getPrototypeOf(obj),
                 Object.getOwnPropertyDescriptors(obj)
             )
         },
    countProperties(obj){
        var count = 0;
        for(var i in obj) {
            if(obj.hasOwnProperty(i)) {  // 建议加上判断,如果没有扩展对象属性可以不加
                count++;
            }
        }
        return count;
    },
    judgeType(obj) {
        // tostring会返回对应不同的标签的构造函数
        const toString = Object.prototype.toString;
        const map = {
            '[object Boolean]': 'boolean',
            '[object Number]': 'number',
            '[object String]': 'string',
            '[object Function]': 'function',
            '[object Array]': 'array',
            '[object Date]': 'date',
            '[object RegExp]': 'regExp',
            '[object Undefined]': 'undefined',
            '[object Null]': 'null',
            '[object Object]': 'object',
        };
        if (obj instanceof Element) {
            return 'element';
        }
        return map[toString.call(obj)];
    },
    deepClone(data) {
        const type = this.judgeType(data);
        let obj;
        if (type === 'array'){
            obj = [];
        } else if (type === 'object') {
            obj = {};
        } else {
            // 不再具有下一层次
            return data;
        }
        if (type === 'array'){
            for (let i = 0, len = data.length; i < len; i++) {
                obj.push(this.deepClone(data[i]));
            }
        } else if (type === 'object') {
            // 对原型上的方法也拷贝了....
            for (const key in data) {
                obj[key] = this.deepClone(data[key]);
            }
        }
        return obj;
    },
    autoMap(source,target,mode){
         //mode hard 若目标不存在来源字段 则拓展目标
          //mode soft  若目标不存在来源字段 则不予影射
          for (let field in source){
               if (!!target[field]&&mode =='hard'){
                    target[field] = source[field]
               }else{
                   target[field] = source[field]
               }
          }
    },
     getVal(object,path){
           let pathArr = path.split('.')
          let pointer =object
          for (let i = 0; i<pathArr.length;i++){
                if (pointer[pathArr[i]]!= undefined){
                    pointer = pointer[pathArr[i]]
                }else{
                     return null
                }
          }
           return pointer
     }
}