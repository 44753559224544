<template>
  <div class="container">
    <div class="text-center">
      <!-- logo -->
      <div>
        <a>
          <img class="logo" :src="tentantInfo.tenantLoinLogo" />
        </a>
      </div>
    </div>

    <!-- 租户切换 -->
    <div style="margin: 20px 0">
      <!-- *ngIf="showTenantChange()" -->
      <tenant-change v-if="showTenantChange && isHost"></tenant-change>
    </div>

    <router-view />

    <!-- 语言选择 
   <div class="text-center">{{l('LanguageSelection')}}</div>
    <account-languages></account-languages>
-->
    <!-- 底部信息 -->
    <div class="global-footer">
      <global-footer :links="links">
        Copyright
        <i class="anticon anticon-copyright"></i>
        {{ currentYear }}
        <b>Version</b>
        {{ versionText }}
        <a :href="tentantInfo.productionCompanyWebAdress" target="_blank">{{
          tentantInfo.productionCompanyName
        }}</a
        >出品
      </global-footer>
    </div>
  </div>
</template>

<script>
import { GlobalFooter } from "@/components";
import { AccountLanguages } from "./account-languages";
import { TenantChange } from "./tenant-change";

import { AppComponentBase } from "@/shared/component-base";
import { abpService, appSessionService } from "@/shared";
import { AppConsts } from "@/abpPro/AppConsts";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  name: "account-layout",
  mixins: [AppComponentBase],
  components: {
    GlobalFooter,
    AccountLanguages,
    TenantChange,
  },
  data() {
    return {
      links: [
        {
          title: "关于我们",
          href: "",
        },
        {
          title: "隐私",
          href: "",
        },
        {
          title: "条款",
          href: "",
        },
      ],
      commonService: null,
      hoverTentantName: "",
      isHost: true,
      tentantInfo: {
        id: "",
        sysTenantId: "",
        systemName: "裕米灵工",
        systemHeadImage: "/assets/images/user.png",
        tenantLoinLogo: "https://static.dianmi-north.cn/dm_logo.png",
        tenantAboutUsLink: "",
        tenantPrivacyLink: "",
        tanantClauseLink: "",
        productionCompanyName: "点米",
        productionCompanyWebAdress: "http://www.dianmi365.com/",
      },
    };
  },
  computed: {
    currentYear: (vm) => {
      return new Date().getFullYear();
    },
    versionText: (vm) => {
      return appSessionService.application.version;
    },
    showTenantChange: (vm) => {
      return abpService.abp.multiTenancy.isEnabled;
    },
    tentantName() {
      return appSessionService.getShownTentantName();
    },
  },
  async created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);

    const urlTenancyName = await this._initTentent();
    console.log("urlTenancyName", urlTenancyName);
    console.log("session", appSessionService);
    if (urlTenancyName) {
      const isTData = await this._isTenantAvailable(urlTenancyName);
      console.log(isTData);
      if (isTData !== null) {
        this._getByTentantName(urlTenancyName);
        if (
          !appSessionService.tenant ||
          !appSessionService.tenant.tenancyName
        ) {
          setTimeout(() => {
            // window.location.reload();
            location.href = AppConsts.appBaseUrl;
          }, 500);
        }
      }
    } else {
      let tentantName = appSessionService.getShownTentantName();
      // console.log("tentantName::::::::::::::::::", tentantName);
      if (tentantName) {
        this._getByTentantName(tentantName);
      } else {
        let info = sessionStorage.getItem("tentantInfo");
        if (info) {
          let tentantInfo = JSON.parse(info);
          // console.log("tentantInfo:::", tentantInfo);
          this.init(tentantInfo);
        } else {
          // abpService.abp.multiTenancy.setTenantIdCookie(undefined);
          // sessionStorage.removeItem("tentantInfo");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500)
        }
      }
    }
  },
  methods: {
    // 提取tenancyName
    async _initTentent() {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Tenant/GetByLink",
          params: {
            //link: "test1.andyguo.cn",
            link: window.location.host,
          },
        });
        if (res != null) {
          this.hoverTentantName = res.tenancyName;
          this.isHost = false;
          //如果已经是当前租户了，就不再刷新了
          if (appSessionService.tenantId == res.sysTenantId) {
            return "";
          }

          return this.hoverTentantName;
        }
        return "";
      } catch (error) {
        console.log(error);
        return "";
      }
    },
    // url上的tenancyName参数是否租户
    async _isTenantAvailable(tenancyName) {
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Account/IsTenantAvailable",
          params: { tenancyName: tenancyName },
        });
        if (res.state && res.state == "Available") {
          abpService.abp.multiTenancy.setTenantIdCookie(res.tenantId);
          return res;
        } else {
          if (
            appSessionService.tenant &&
            appSessionService.tenant.tenancyName
          ) {
            abpService.abp.multiTenancy.setTenantIdCookie(undefined);
            sessionStorage.removeItem("tentantInfo");
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
          return null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    init(tentantInfo) {
      this.tentantInfo = {
        ...tentantInfo,
        systemHeadImage: `${AppConsts.uploadBaseUrl}${tentantInfo.systemHeadImage}`,
        tenantLoinLogo: `${AppConsts.uploadBaseUrl}${tentantInfo.tenantLoinLogo}`,
      };
      this.links[0].href = tentantInfo.tenantAboutUsLink;
      this.links[1].href = tentantInfo.tenantPrivacyLink;
      this.links[2].href = tentantInfo.tanantClauseLink;
    },
    _getByTentantName(newTenancyName) {
      let _this = this;
      $.get(
        `${this.$apiUrl}/api/services/app/SysInterface/GetByTentantName?tentantName=${newTenancyName}`,
        function (data, status) {
          if (status === "success") {
            let res = data.result;
            if (res) {
              sessionStorage.setItem("tentantInfo", JSON.stringify(res));
              _this.init(res);
            }
          }
        }
      );
      // try {
      //   let res = await this.commonService.get({
      //     url: "/api/services/app/SysInterface/GetByTentantName",
      //     params: {
      //       tentantName: newTenancyName,
      //     },
      //   });
      //   if (res) {
      //     sessionStorage.setItem("tentantInfo", JSON.stringify(res));
      //     this.init(res)
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
  },
};
</script>

<style scoped>
@import "./account-layout.less";
</style>
