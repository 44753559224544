import Vue from "vue";
import VueRouter from "vue-router";
import { accountRouting } from "@/account";
import { appRouting } from "@/app";
import { bulletinboardRouting } from "@/bulletinboard";

Vue.use(VueRouter);

const rootRooting = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [...accountRouting, ...appRouting, ...bulletinboardRouting],
});

export default rootRooting;
