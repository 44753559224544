import {
  ArrayConfig,
  ArrayServiceArrToTreeNodeOptions,
  ArrayServiceArrToTreeOptions,
  // ArrayServiceGetKeysByTreeNodeOptions,
  // ArrayServiceTreeToArrOptions
} from './interface';

class MyArrayService {
  c: ArrayConfig;

  constructor() {
    this.configService(null);
  }

  /**
     * 配置服务
     * @param config
     */
  configService(config: ArrayConfig) {
    this.c = {
      deepMapName: 'deep',
      parentMapName: 'parent',
      idMapName: 'id',
      parentIdMapName: 'parent_id',
      childrenMapName: 'children',
      titleMapName: 'title',
      checkedMapname: 'checked',
      selectedMapname: 'selected',
      expandedMapname: 'expanded',
      disabledMapname: 'disabled',
      ...(config && config),
    };
  }

  arrToTreeNode(arr: any[], options?: ArrayServiceArrToTreeNodeOptions): any[] {
    const opt = {
      idMapName: this.c.idMapName,
      parentIdMapName: this.c.parentIdMapName,
      titleMapName: this.c.titleMapName,
      isLeafMapName: 'isLeaf',
      checkedMapname: this.c.checkedMapname,
      selectedMapname: this.c.selectedMapname,
      expandedMapname: this.c.expandedMapname,
      disabledMapname: this.c.disabledMapname,
      cb: null,
      ...options,
    } as ArrayServiceArrToTreeNodeOptions;
    const tree = this.arrToTree(arr, {
      idMapName: opt.idMapName,
      parentIdMapName: opt.parentIdMapName,
      childrenMapName: 'children',
    });

    this.visitTree(tree, (item: any, parent: any, deep: number) => {
      item.key = item[opt.idMapName!];
      item.title = item[opt.titleMapName!];
      item.checked = item[opt.checkedMapname!];
      item.selected = item[opt.selectedMapname!];
      item.expanded = item[opt.expandedMapname!];
      item.disabled = item[opt.disabledMapname!];
      if (item[opt.isLeafMapName!] == null) {
        let isLeafVal = !item.items || item.children.length === 0
        item.isLeaf = isLeafVal;
      } else {
        item.isLeaf = item[opt.isLeafMapName!];
      }
      if (opt.cb) {
        opt.cb(item, parent, deep);
      }

      item.origin = item;
    });
    return arr;
  }

  arrToTree(arr: any[], options?: ArrayServiceArrToTreeOptions): any[] {
    const opt = {
      idMapName: this.c.idMapName,
      parentIdMapName: this.c.parentIdMapName,
      childrenMapName: this.c.childrenMapName,
      cb: null,
      ...options,
    } as ArrayServiceArrToTreeOptions;
    let tree: any[] = [];
    let childrenOf = {};

    for (const item of arr) {
      const id = item[opt.idMapName!];
      const pid = item[opt.parentIdMapName!];
      childrenOf[id] = childrenOf[id] || [];
      item[opt.childrenMapName!] = childrenOf[id];
      if (item.items && item.items.length) {
        item[opt.childrenMapName!] = item.items
      }
      if (opt.cb) {
        opt.cb(item);
      }
      if (pid) {
        childrenOf[pid] = childrenOf[pid] || [];
        childrenOf[pid].push(item);
      } else {
        tree.push(item);
      }
    }
    return tree
  }

  visitTree(
    tree: any[],
    cb: (item: any, parent: any, deep: number) => void,
    options?: {
      /** 子项名，默认：`'children'` */
      childrenMapName?: string;
    },
  ): void {
    options = {
      childrenMapName: this.c.childrenMapName,
      ...options,
    };
    const inFn = (data: any[], parent: any, deep: number) => {
      for (const item of data) {
        cb(item, parent, deep);
        const childrenVal = item[options!.childrenMapName!];
        if (childrenVal && childrenVal.length > 0) {
          inFn(childrenVal, item, deep + 1);
        }
      }
    };
    inFn(tree, null, 1);
  }
}


const myArrayService = new MyArrayService();
export default myArrayService;