export * from './global-footer';
export * from './reuse-tab';
export * from './page-header';
export * from './list-table';
export * from './table-no-page';

// chart
import Bar from '@/components/Charts/Bar.vue';
import ChartCard from '@/components/Charts/ChartCard.vue';
import Liquid from '@/components/Charts/Liquid.vue';
import MiniArea from '@/components/Charts/MiniArea.vue';
import MiniSmoothArea from '@/components/Charts/MiniSmoothArea.vue';
import MiniBar from '@/components/Charts/MiniBar.vue';
import MiniProgress from '@/components/Charts/MiniProgress.vue';
import Radar from '@/components/Charts/Radar.vue';
import RankList from '@/components/Charts/RankList.vue';
import TransferBar from '@/components/Charts/TransferBar.vue';
import TagCloud from '@/components/Charts/TagCloud.vue';

// pro components
import Ellipsis from '@/components/Ellipsis';
import FooterToolbar from '@/components/FooterToolbar';
import NumberInfo from '@/components/NumberInfo';
import Trend from '@/components/Trend';
import MultiTab from '@/components/MultiTab';
import IconSelector from '@/components/IconSelector';
import StandardFormRow from '@/components/StandardFormRow';
import ArticleListContent from '@/components/ArticleListContent';

import './ZrCustomizeForm/core/components_use';

import ReactTable from '@/components/react-table';
import EleTable from '@/components/ele-table';
import ReactModify from '@/components/react-modify';
import RealName from '@/components/real-name';
import BankAccount from '@/components/real-name';
import Seal from '@/components/seal';


import * as all from './ZrCustomizeForm/mini.js';
const setFormDesignConfig = all.setFormDesignConfig;
const ZrFormDesigner = all.ZrFormDesign;
const ZrFormPreview = all.ZrFormPreview;
const ZrFormBuild = all.ZrFormBuild;
const ZrFormItem = all.ZrFormItem;

export {
  Bar,
  ChartCard,
  Liquid,
  MiniArea,
  MiniSmoothArea,
  MiniBar,
  MiniProgress,
  Radar,
  TagCloud,
  RankList,
  TransferBar,
  Trend,
  Ellipsis,
  FooterToolbar,
  NumberInfo,
  MultiTab,
  IconSelector,
  StandardFormRow,
  ArticleListContent,
  setFormDesignConfig,
  ZrFormDesigner,
  ZrFormBuild,
  ZrFormItem,
  ZrFormPreview,
  ReactTable,
  EleTable,
  ReactModify,
  RealName,
  BankAccount,
  Seal,
};
