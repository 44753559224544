import { RouteConfig } from 'vue-router';
import { LayoutBlock, LayoutBlockKeepAlive } from '@/layout';

const trainRouting: RouteConfig[] = [
    {
        path: 'train',
        meta: { title: "培训管理" },
        component: LayoutBlockKeepAlive,
        redirect: '/app/train',
        children: [
            { path: 'train', component: () => import('./train/list.vue'), meta: { title: "培训管理" } },
            { path: 'exam', component: () => import('./exam/list.vue'), meta: { title: "试题管理" } }
        ]
    },
];

export default trainRouting;
