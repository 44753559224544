/*
 * author zr
 * date 2019-11-20
 */
import ZrFormPreview from "./index.vue";

ZrFormPreview.install = function(Vue) {
  Vue.component(ZrFormPreview.name, ZrFormPreview);
};

export default ZrFormPreview;
