export const environment = {
  production: process.env.NODE_ENV !== 'development',
  externalLogin: {
    redirectUri: 'http://localhost:8000/account/login-callback',
    qq: {
      appId: '101615423',
    },
    wecaht: {
      appId: 'your_app_id',
    },
    microsoft: {
      consumerKey: '6985604e-5ac8-4c9f-899c-717e3b423c0d'
    },
    facebook: {
      appId: '2483742038386623',
    },
    google: {
      clientId: '1059737066448-rhcatg45sp7jlpo1kr13hoavcmo36a0k.apps.googleusercontent.com',
    }
  }
};
