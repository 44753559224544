var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"modal-header"},[(_vm.params.type === 'edit')?_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.l("Edit"))+" ")]):_vm._e(),(_vm.params.type === 'preview')?_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.l("查看"))+" ")]):_vm._e(),(_vm.params.type === 'create')?_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.l("创建"))+" ")]):_vm._e(),(_vm.params.type === 'others')?_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.l("创建"))+" ")]):_vm._e()]),_c('div',{staticClass:"modalform-scroll"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"form":_vm.form}},[_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":12}},_vm._l((_vm.list),function(item,index){return _c('a-col',{key:index,attrs:{"span":item.span ? item.span : 12}},[(item.type === 'commonInput')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (item.value)),
              {
                rules:
                  item.rules && item.rules.length ? item.rules : _vm.defaultRules,
              },
              { defaultValue: item.defaultValue } ]),expression:"[\n              `${item.value}`,\n              {\n                rules:\n                  item.rules && item.rules.length ? item.rules : defaultRules,\n              },\n              { defaultValue: item.defaultValue },\n            ]"}],attrs:{"disabled":item.disabled,"placeholder":_vm.l('')},on:{"change":item.changeFn}})],1):_vm._e(),(item.type === 'numberInput')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                ("" + (item.value)),
                {
                  rules:
                    item.rules && item.rules.length ? item.rules : _vm.defaultRules,
                  initialValue: item.defaultValue,
                } ]),expression:"[\n                `${item.value}`,\n                {\n                  rules:\n                    item.rules && item.rules.length ? item.rules : defaultRules,\n                  initialValue: item.defaultValue,\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":item.disabled,"min":item.min,"max":_vm.returnNumerInput(item.max)},on:{"change":item.changeFn}})],1):_vm._e(),(item.type === 'switch')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([("" + (item.value)), { valuePropName: 'checked' }]),expression:"[`${item.value}`, { valuePropName: 'checked' }]"}]})],1):_vm._e(),(item.type === 'datePicker')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (item.value)),
              {
                rules: item.rules && item.rules.length ? [].concat( item.rules ) : [],
              } ]),expression:"[\n              `${item.value}`,\n              {\n                rules: item.rules && item.rules.length ? [...item.rules] : [],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":item.disabled,"placeholder":'请选择日期'},on:{"change":item.changeFn}})],1):_vm._e(),(item.type === 'monthPicker')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-month-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                ("" + (item.value)),
                {
                  rules: item.rules && item.rules.length ? [].concat( item.rules ) : [],
                } ]),expression:"[\n                `${item.value}`,\n                {\n                  rules: item.rules && item.rules.length ? [...item.rules] : [],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":item.disabled,"placeholder":'请选择日期',"valueFormat":"YYYY-MM"},on:{"change":item.changeFn}})],1):_vm._e(),(item.type === 'rangeDatePicker')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                ("" + (item.value)),
                {
                  rules: item.rules && item.rules.length ? [].concat( item.rules ) : [],
                } ]),expression:"[\n                `${item.value}`,\n                {\n                  rules: item.rules && item.rules.length ? [...item.rules] : [],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":item.disabled,"placeholder":['开始日期', '结束日期'],"valueFormat":"YYYY-MM-DD"},on:{"change":item.changeFn}})],1):_vm._e(),(item.type === 'textarea')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (item.value)),
              {
                rules:
                  item.rules && item.rules.length
                    ? item.rules.concat( [_vm.defaultRules])
                    : _vm.defaultRules,
              } ]),expression:"[\n              `${item.value}`,\n              {\n                rules:\n                  item.rules && item.rules.length\n                    ? [...item.rules, defaultRules]\n                    : defaultRules,\n              },\n            ]"}],attrs:{"type":"textarea","disabled":item.disabled,"placeholder":_vm.l('请输入')}})],1):_vm._e(),(item.type === 'commonSelect')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required,"labelCol":{ span: item.labelSpan ? item.labelSpan : 6 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (item.value)),
              {
                rules:
                  item.rules && item.rules.length ? item.rules : _vm.defaultRules,
                initialValue: item.defaultValue,
              } ]),expression:"[\n              `${item.value}`,\n              {\n                rules:\n                  item.rules && item.rules.length ? item.rules : defaultRules,\n                initialValue: item.defaultValue,\n              },\n            ]"}],attrs:{"show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"请选择","disabled":item.disabled},on:{"change":item.changeFn}},_vm._l((item.defaultSelectList),function(it,i){return _c('a-select-option',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1):_vm._e(),(item.type === 'commonTreeSelect')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (item.value)),
              {
                rules:
                  item.rules && item.rules.length ? item.rules : _vm.defaultRules,
                initialValue: item.defaultValue + '',
              } ]),expression:"[\n              `${item.value}`,\n              {\n                rules:\n                  item.rules && item.rules.length ? item.rules : defaultRules,\n                initialValue: item.defaultValue + '',\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":item.defaultSelectList,"placeholder":"请选择","tree-default-expand-all":""},on:{"change":item.changeFn}})],1):_vm._e(),(item.type === 'multipleSelect')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (item.value)),
              {
                rules:
                  item.rules && item.rules.length ? item.rules : _vm.defaultRules,
                initialValue: item.defaultValue,
              } ]),expression:"[\n              `${item.value}`,\n              {\n                rules:\n                  item.rules && item.rules.length ? item.rules : defaultRules,\n                initialValue: item.defaultValue,\n              },\n            ]"}],attrs:{"mode":"multiple","placeholder":"请选择"},on:{"change":item.changeFn}},_vm._l((item.defaultSelectList),function(it,i){return _c('a-select-option',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1):_vm._e(),(item.type === 'radio')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (item.value)),
              {
                rules:
                  item.rules && item.rules.length ? item.rules : _vm.defaultRules,
                initialValue: item.defaultValue,
              } ]),expression:"[\n              `${item.value}`,\n              {\n                rules:\n                  item.rules && item.rules.length ? item.rules : defaultRules,\n                initialValue: item.defaultValue,\n              },\n            ]"}]},_vm._l((item.defaultSelectList),function(it,i){return _c('a-radio',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1):_vm._e(),(item.type === 'checkbox')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (item.value)),
              { initialValue: item.defaultValue } ]),expression:"[\n              `${item.value}`,\n              { initialValue: item.defaultValue },\n            ]"}],staticStyle:{"width":"100%"}},[_c('a-row',[_c('a-col',_vm._l((item.defaultSelectList),function(it,i){return _c('a-checkbox',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1)],1)],1):_vm._e(),(item.type === 'upload')?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('a-upload',{attrs:{"action":item.uploadUrl,"multiple":true,"list-type":item.listType || 'text',"data":{ subdir: item.subdirName },"file-list":item.value,"showUploadList":{
                showPreviewIcon: true,
                showRemoveIcon:
                  item.showRemoveIcon && item.showRemoveIcon === '0'
                    ? false
                    : true,
              }},on:{"change":item.changeFn,"preview":_vm.previewFn}},[(_vm.params.type !== 'preview' &&
                item.value.length < item.limitedLength
                )?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传附件 ")],1):_vm._e(),(_vm.params.type === 'preview' &&
                (!item.value || !item.value.length)
                )?_c('span',[_vm._v("暂无")]):_vm._e()],1)],1):_vm._e(),(item.type === 'desc' && item.value)?_c('a-form-item',{attrs:{"label":item.label,"required":item.required}},[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e()],1)}),1)],1)],1),(_vm.params.type !== 'preview')?_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('a-icon',{attrs:{"type":"close-circle"}}),_vm._v(" "+_vm._s(_vm.params.cancelBtnTxt ? _vm.params.cancelBtnTxt : _vm.l("Cancel"))+" ")],1),_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":function($event){return _vm.handleSubmit()}}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.params.okBtnTxt ? _vm.params.okBtnTxt : _vm.l("Save"))+" ")],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }