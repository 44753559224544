import { RouteConfig } from "vue-router";
import { LayoutBlockKeepAlive } from "@/layout";
import SignAccount from '@/app/customeraccount/index.vue';

const lingGongRouting: RouteConfig[] = [
  {
    path: "linggong",
    meta: { title: "灵工管理" },
    component: LayoutBlockKeepAlive,
    redirect: "/app/linggong/customer",
    children: [
      {
        path: "customer",
        component: () => import("./customer/customer.vue"),
        meta: { title: "客户管理" },
      },
      {
        path: 'signaccount',
        component: SignAccount,
        meta: { title: '签约账户' }
      },
      {
        path: "collectionform",
        component: () => import("./collectionform/index.vue"),
        meta: { title: "自定义表单管理" },
      },
      {
        path: "contract",
        component: () => import("./contract/contract.vue"),
        meta: { title: "线下合同管理" },
      },
      { path: 'task', component: () => import('./task/index.vue'), meta: { title: "任务管理" } },
      { path: 'projectuser', component: () => import('./projectuser/index.vue'), meta: { title: "项目人员管理" } },
      { path: 'personnel', component: () => import('./personnel/index.vue'), meta: { title: "人员管理" } },
      { path: 'feedback', component: () => import('./feedback/index.vue'), meta: { title: "意见反馈" } },
      {
        path: 'advertisingconfig',
        component: () => import('./advertisingconfig/index.vue'),
        meta: { title: "广告设置" }
      },
      { path: 'apply', component: () => import('./apply/index.vue'), meta: { title: "报名管理" } },
      { path: 'template', component: () => import('./template/index.vue'), meta: { title: "模板管理" } },
      { path: 'settlement', component: () => import('./salary/settlement/list.vue'), meta: { title: "结算管理" } },
      { path: 'salary', component: () => import('./salary/issue/list.vue'), meta: { title: "发放管理" } },
      { path: 'detail', component: () => import('../financial/detail/list.vue'), meta: { title: "发放明细" } },
      { path: 'roster', component: () => import('./personnel/roster/list.vue'), meta: { title: "人员花名册" } },
      { path: 'workOrder', component: () => import('./workorder/index.vue'), meta: { title: "工单管理" } },
      { path: 'templeteManage', component: () => import('./templeteManage/templeteManage.vue'), meta: { title: "协议模版管理" } },
      { path: 'taskReportNewpannel', component: () => import('./task/task-report-pannel/task-report-newpannel.vue'), meta: { title: "任务管理(执行记录)" } },
      { path: 'receipt', component: () => import('./receipt/receipt.vue'), meta: { title: "发票" } },
      { path: 'fieldlib', component: () => import('./fieldlib/index.vue'), meta: { title: "表单控件管理" } },
      { path: 'insurance', component: () => import('./insurance/list.vue'), meta: { title: "保险数据管理" } },
      { path: 'exportTask', component: () => import('./exporttask/index.vue'), meta: { title: "导出任务" } },
      { path: 'soho', component: () => import('./soho/index.vue'), meta: { title: "报备单管理" } },
      { path: 'termination', component: () => import('./termination/list.vue'), meta: { title: "个体户解约申请" } },
      { path: 'tpsInvoice', component: () => import('./tpsInvoice/list.vue'), meta: { title: "三方开票申请" } },
      { path: 'individualBusiness', component: () => import('./individualBusiness/list.vue'), meta: { title: "新经济个体工商户管理" } },

      // { path: 'taxPayment', component: () => import('./taxPayment/taxPayment.vue'), meta: { title: "完税管理" } },
    ],
  },
];

const lingGongBlockRouting: RouteConfig[] = [
  {
    path: "collectionformEdit/:id",
    component: () => import("./collectionform/formedit.vue"),
  },
  {
    path: "newCollectionformEdit/:id",
    component: () => import("./collectionform/customerformedit.vue"),
  },
  { path: 'nAddTemplete/:id', component: () => import('./templeteManage/addTemplete.vue'), meta: { title: "添加协议模版" } },
  {
    path: 'nSignMethod/:id',
    component: () => import('./templeteManage/signMethod.vue'),
    meta: { title: "设置签署方式" }
  },
  {
    path: 'nChooseSeal/:id',
    component: () => import('./templeteManage/chooseSeal.vue'),
    meta: { title: "选择用章" }
  },
];

export { lingGongRouting, lingGongBlockRouting };
