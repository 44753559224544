<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>账户流水</span>
      </div>
    </div>
    <div>
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="true"
        maxHeight=""
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
        :pageSizes="pageSizeOptions"
        :handleHeight="100"
        :pageSize="request.maxResultCount"
        @emitRefreshData="getData"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row :gutter="24">
          <!-- 搜索 -->
          <a-col
            :xs="{ span: 16 }"
            :sm="{ span: 12 }"
            :md="{ span: 10 }"
            :lg="{ span: 8 }"
          >
            <div class="colbox">

              <a-range-picker
                :placeholder="[l('起始日期'), l('结束日期')]"
                v-model="startToEndDate"
              />
            </div>
          </a-col>

          <a-col
            :xs="{ span: 10 }"
            :sm="{ span: 10 }"
            :md="{ span: 10 }"
            :lg="{ span: 10 }"
          >
            <a-button type="primary" @click="searchData" icon="search"
              >查询</a-button
            >
          </a-col>
          <a-col
          v-if="isGranted('Pages.LGModul.SignAccount.CreatedDownloadReTask')"
            :xs="{ span: 3 }"
            :sm="{ span: 3 }"
            :md="{ span: 3 }"
            :lg="{ span: 3 }"
          >
            <a-button type="primary" @click="CreatedDownloadTask(0)"
              >生成交易单</a-button
            >
          </a-col>
           <a-col
          v-if="isGranted('Pages.LGModul.SignAccount.CreatedDownloadReTask')"
            :xs="{ span: 3 }"
            :sm="{ span: 3 }"
            :md="{ span: 3 }"
            :lg="{ span: 3 }"
          >
              <a-button type="primary" @click="CreatedDownloadTask(1)"
              >生成代发明细</a-button
            >
          </a-col>
        </a-row>
      </ele-table>
    </div>
  </a-spin>
</template>

<script>
import EleTable from "@/components/ele-table";
import { ModalComponentBase } from "@/shared/component-base";
import moment from "moment";
export default {
  mixins: [ModalComponentBase],
  components: {
    EleTable,
  },
  name: "create-or-edit-signaccount",
  data() {
    return {
      spinning: false,
      columns: [],
      tableData: [],
      totalItems: 0,
      pageNumber: 1,
      actionsType: null,
      pageSizeOptions: ["10", "20", "50", "100", "200"],
      request: { sorting: "", maxResultCount: 10, skipCount: 0 },
      filterText: "",
      customerData: [],
      startToEndDate: [],
      customerId: null,
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.initColumns();
    this.getData();
  },
  mounted() {},
  methods: {
    updatePageChange(info) {
      const { page, skipCount, pageSize } = info;
      this.request.skipCount = skipCount * (page - 1);
      this.request.maxResultCount = pageSize;
      this.getData();
    },
    updateShowSizeChange(info) {
      const { current, size } = info;
      this.request.maxResultCount = size;
      this.request.skipCount = 0;
      this.getData();
    },
    getData() {
      this.spinning=true;
      const options = {
        url: `${this.$apiUrl}/api/services/app/CustomerProvideConfig/GetDownloadRecordByPage`,
        method: "POST",
        data: {
          ...this.request,
          Appid: this.id,
        },
      };
      this.$api
        .request(options)
        .then((response) => {
          if (response.status !== 200) {
            abp.message.error(this.l("服务异常，请稍后重试"));
            return;
          }
          const res = response.data;
          this.tableData = res.items;
          this.tableData.map((item) => {
            item.startDateTime = item.startDateTime
              ? moment(item.startDateTime).format("YYYY-MM-DD")
              : "-";
            item.endDateTime = item.endDateTime
              ? moment(item.endDateTime).format("YYYY-MM-DD")
              : "-";
            item.createdTime = item.createdTime
              ? moment(item.createdTime).format("YYYY-MM-DD")
              : "-";
          });
          this.totalItems = res.totalCount;
          this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
           this.spinning=false;
        })
        .catch((e) => {
          this.spinning=false;
          console.error(e);
          abp.message.warn(this.l("DefaultErrorMessage"));
        });
    },
    searchData() {
      this.request.skipCount = 0;
      this.getData();
    },
    CreatedDownloadTask(type) {
      this.spinning=true;
      if (this.startToEndDate.length < 2) {
        this.spinning=false;
        abp.message.error(this.l("请选择日期"));
        return;
      }
      const options = {
        url: `${this.$apiUrl}/api/services/app/CustomerProvideConfig/CreatedDownloadTask`,
        method: "POST",
        data: {
          startDateTime: this.startToEndDate[0],
          endDateTime: this.startToEndDate[1],
          downLoadTaskType:type,
          appid: this.id,
        },
      };
      this.$api
        .request(options)
        .then((response) => {
          if (response.status !== 200) {
            abp.message.error(this.l(response.message));
            return;
          }
          abp.message.success(this.l("生成成功"));
          this.spinning=false;
          this.getData();
        })
        .catch((e) => {
          console.error(e);
           this.spinning=false;
          abp.message.warn(this.l("DefaultErrorMessage"));
        });
    },
    initColumns() {
      this.columns = [
         {
          title: this.l("交易类型"),
          dataIndex: "downLoadTaskTypeStr",
          sorter: false,
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "downLoadTaskTypeStr" },
        },
        {
          title: this.l("交易开始日期"),
          dataIndex: "startDateTime",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: "startDateTime" },
        },
        {
          title: this.l("交易结束日期"),
          dataIndex: "endDateTime",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: "endDateTime" },
        },
        {
          title: this.l("交易账单生成时间"),
          dataIndex: "createdTime",
          sorter: false,
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "createdTime" },
        },
        {
          title: this.l("备注"),
          dataIndex: "remark",
          sorter: false,
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "remark" },
        },
      ];
      const that = this;
      this.actionsType = {
        type: "customerAccount",
        isShow: true,
        fns: {
          detailList: [
            {
              name: this.l("下载"),
              icon: "edit",
              granted: true,
              fn: async (row) => {
                if (row.downLoadUrl.indexOf("http") >= 0) {
                  var array =row.downLoadUrl.split(',');
                  console.log(array)
                  array.forEach(element => {
                    window.open(element, '_blank');
                    // this.downloadFile(element);
                  });
                }
              },
              showFunc: (row) => {
                return row.downLoadUrl !== null;
              },
            },
          ],
        },
        width: "100px",
        minWidth: "100px",
      };
    },
    async downloadFile(url){
      let response = await fetch(url)
      // 内容转变成blob地址
      let blob = await response.blob()
      // 创建隐藏的可下载链接
      let objectUrl = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.style.display = "none";
      //地址
      a.href = objectUrl
      // 触发点击
      document.body.appendChild(a)
      a.click()
      //移除
      URL.revokeObjectURL(a.href);
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
