import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const adminRouting: RouteConfig[] = [
    {
        path: 'demo',
        meta: { title: "系统" },
        component: LayoutBlock,
        redirect: '/app/demo/demoui',
        children: [
            { path: 'demoui', component: () => import(/* webpackChunkName: "admin" */ './demoui.vue'), meta: { title: "users" } },
        ]
    },
];

export default adminRouting;
