import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const codeRouting: RouteConfig[] = [
  {
    path: "main",
    component: LayoutBlock,
    meta: { title: "代码生成" },
    children: [
      {
        path: "/app/codesmart/smart",
        name: "smart",
        component: () =>
          import(/* webpackChunkName: "main" */ "./smart/index.vue"),
        meta: { permission: "Pages.Administration", title: "代码生成" },
      },
    ],
  },
];

export default codeRouting;
