import axios from 'axios';
import { message, Modal } from 'ant-design-vue';
import { AppConsts } from '@/abpPro/AppConsts';
import { abpService } from '@/shared/abp';
import { localizationService } from '@/shared/i18n';


const apiHttpClient = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 300000
});

// request interceptor
apiHttpClient.interceptors.request.use(function (config) {
  if (!!abpService.abp.auth.getToken()) {
    config.headers.common['Authorization'] = 'Bearer ' + abpService.abp.auth.getToken() || '';
  }
  config.headers.common['.AspNetCore.Culture'] = abpService.abp.utils.getCookieValue('Abp.Localization.CultureName');
  config.headers.common['Abp-TenantId'] = abpService.abp.multiTenancy.getTenantIdCookie() || '';
  // config.headers.common['WechatAppletTenantId'] = abpService.abp.multiTenancy.getTenantIdCookie() || '';

  return config;

}, function (error) {

  return Promise.reject(error);
});

const showErrorHandle = (msg) => {
  let lines = msg.split('\r\n');
  if (lines.length <= 1) {
    lines = msg.split('\n');
  }
  function getEle(h) {
    const array = [h("div", { style: "max-width:600px;width:100%;margin:10px 0 10px 0;font-size:15px;font-weight:500;text-indent:5px;" }, "请求错误")];
    const temps = lines.map((line, index) => {
      return [
        h("span", null, line),
        index < lines.length - 1 ? h("br") : null,
      ];
    });
    array.push(...temps);
    array.push(h("div", {
      on: { click: () => message.destroy() },
      style: "border-radius:5px;width:100px;margin-left:calc(100% - 100px);height:32px;line-height:32px;margin-top:10px;margin-bottom:10px;background-color:rgb(73,169,238);color:white;font-size:15px;font-weight:500;text-align: center;"
    }, "ok"));
    return array;
  }
  message.error({
    type: 'error',
    content: h => h(
      "span",
      { style: "display: inline-table;text-align: left;" },
      getEle(h)
    ),
    duration: 5,
  });
}

// response error interceptor
apiHttpClient.interceptors.response.use((response) => {
  if (response.data['__abp']) {
    response.data = response.data.result;
  }
  return response;
}, (error) => {
  if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
    const msg = error.response.data.error.message;
    showErrorHandle(msg);
    const { status } = error.response;
    if (status === 401) {
      abpService.abp.auth.clearToken();
      location.href = AppConsts.appBaseUrl;
    }
    console.error(error.response.data.error.details);
  } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
    const msg = error.response.data.error.message;
    let lines = msg.split('\r\n');
    if (lines.length <= 1) {
      lines = msg.split('\n');
    }

    Modal.error({
      title: localizationService.l('请求错误'),
      content: h => h(
        "div",
        null,
        lines.map((line, index) => {
          return [
            h("span", null, line),
            index < lines.length - 1 ? h("br") : null,
          ];
        })
      ),
      width: 600
    });
    //showErrorHandle(msg);
    if (error.response.data.error.message === '当前用户没有登录到系统！') {
      abpService.abp.auth.clearToken();
      setTimeout(() => {
        location.href = AppConsts.appBaseUrl;
      }, 1000)
    }
  } else if (!error.response) {
    showErrorHandle(localizationService.l('请求错误，请稍后重试'))
  }

  return Promise.reject(error);
});

export default apiHttpClient;