import { Menu } from '@/shared/common';

/**
 * 全局的左侧边栏的菜单导航配置信息
 */
export class AppMenus {
  public static Menus: Menu[] = [
    {
      // 工作台
      text: '',
      i18n: 'Dashboard',
      acl: undefined,
      icon: 'anticon anticon-home',
      link: '/app/main/dashboard',
    },
    // {
    //     // 租户
    //     text: "",
    //     i18n: "Tenants",
    //     acl: "Pages.Tenants",
    //     icon: "anticon anticon-tool",
    //     link: "/app/admin/tenants",
    // },
    // {
    //     // 版本
    //     text: "",
    //     i18n: "Editions",
    //     acl: "Pages.Editions.Query",
    //     icon: "anticon anticon-layout",
    //     link: "/app/admin/editions",
    // },
    {
      // 代码生成
      text: '代码生成',
      icon: 'anticon anticon-code',
      link: '/app/codesmart/smart',
    },
    {
      // 管理
      text: '',
      i18n: 'Administration',
      acl: 'Pages',
      icon: 'anticon anticon-appstore',
      children: [
        {
          // 组织机构
          text: '',
          i18n: 'OrganizationUnits',
          acl: 'Pages.Administration.OrganizationUnits',
          icon: 'anticon anticon-team',
          link: '/app/admin/organization-units',
        },
        {
          // 角色
          text: '',
          i18n: 'Roles',
          acl: 'Pages.Administration.Roles',
          icon: 'anticon anticon-safety',
          link: '/app/admin/roles',
        },
        {
          // 用户
          text: '',
          i18n: 'Users',
          acl: 'Pages.Administration.Users',
          icon: 'anticon anticon-user',
          link: '/app/admin/users',
        },
        {
          // 租户
          text: '',
          i18n: 'Tenants',
          acl: 'Pages.Tenants',
          icon: 'anticon anticon-tool',
          link: '/app/admin/tenants',
        },
        {
          text: '人员花名册',
          icon: 'anticon anticon-user',
          acl: 'Pages.LGModul.PersonnelRoster.Node',
          link: '/app/linggong/roster',
          sort: 14,
        },
        // // Hangfire管理
        // {
        //     text: '',
        //     i18n: 'HangfireSchedule',
        //     acl: 'Pages.Administration.HangfireDashboard',
        //     link: '/app/admin/hangfire',
        //     icon: { type: 'icon', value: 'setting' }
        // }
      ],
    },
    //  {
    // Wechat 模块
    // text: "",
    //  i18n: "WechatManagement",
    //  icon: "anticon anticon-info-circle",
    //  children: [
    //
    //  {
    //    text: undefined,
    //    i18n: "WechatAppConfig",
    //    acl: "Pages.WechatAppConfig",
    //    icon: "anticon anticon-dashboard",
    //    link: "/app/wechat/wechat-app-config",
    //  },
    // ],
    //},
    {
      //客户管理
      text: '',
      i18n: '客户管理',
      acl: 'Pages.CustomerManager',
      icon: 'anticon anticon-team',
      children: [
        //
        {
          text: '客户管理',
          i18n: 'ManageCompany',
          acl: 'Pages.LGModul.Customer.Node',
          icon: 'anticon anticon-contacts',
          link: '/app/linggong/customer',
        },
        {
          text: '线下合同管理',
          acl: 'Pages.LGModul.OfflineContract.Node',
          icon: 'anticon anticon-calculator',
          link: '/app/linggong/contract',
        },
        {
          text: '签约账户',
          acl: 'Pages.LGModul.SignAccount.Node',
          icon: 'anticon anticon-account-book',
          link: '/app/linggong/signaccount',
        }
        // {
        //   text: "人员花名册",
        //   icon: "anticon anticon-user",
        //   acl: "Pages.LGModul.PersonnelRoster.Node",
        //   link: "/app/linggong/roster",
        //   sort: 14,
        // },
      ],
    },
    {
      //  灵工模块
      text: '',
      i18n: 'LingGongModule',
      acl: 'Pages.LingGong',
      icon: 'anticon anticon-team',
      children: [
        //
        // {
        //     text: "客户管理",
        //     i18n: "ManageCompany",
        //     acl: "Pages.LGModul.Customer.Node",
        //     icon: "anticon anticon-contacts",
        //     link: "/app/linggong/customer",
        // },
        // {
        //     text: "线下合同管理",
        //     acl: "Pages.LGModul.OfflineContract.Node",
        //     icon: "anticon anticon-calculator",
        //     link: "/app/linggong/contract",
        // },
        {
          text: '',
          i18n: '人员管理',
          acl: 'Pages.LGModul.Personal.Node',
          icon: 'anticon anticon-user',
          link: '/app/linggong/personnel',
        },
        {
          // 项目管理
          text: '项目管理',
          icon: 'anticon anticon-project',
          acl: 'Pages.LGModul.Project.Node',
          link: '/app/project/project',
          sort: 8,
        },
        {
          // 项目人员管理
          text: '项目人员管理',
          icon: 'anticon anticon-usergroup-add',
          acl: 'Pages.LGModul.ProjectPersonel.Node',
          link: '/app/linggong/projectuser',
          sort: 9,
        },
        {
          // 任务管理
          text: '任务管理',
          icon: 'anticon anticon-clock-circle',
          acl: 'Pages.LGModul.Task.Node',
          link: '/app/linggong/task',
          sort: 8,
        },
        {
          // 报名管理
          text: '报名管理',
          icon: 'anticon anticon-schedule',
          acl: 'Pages.LGModul.Signup.Node',
          link: '/app/linggong/apply',
          sort: 10,
        },
        {
          // 报名管理
          text: '模板管理',
          icon: 'anticon anticon-table',
          acl: 'Pages.LGModul.Template.Node',
          link: '/app/linggong/template',
          sort: 11,
        },
        {
          text: undefined,
          i18n: 'CollectionFormManage',
          acl: 'Pages.LGModul.CustomForm.Node',
          icon: 'anticon anticon-form',
          link: '/app/linggong/collectionform',
        },
        {
          // 结算管理
          link: '/app/linggong/settlement',
          text: '结算管理',
          acl: 'Pages.LGModul.Settlement.Node',
          icon: 'anticon anticon-menu',
          sort: 12,
        },
        {
          // 账单概览
          text: '',
          i18n: '账单概览',
          acl: 'Pages.BillPermissions',
          icon: 'anticon anticon-profile',
          link: '/app/finanical/bill',
          sort: 13,
        },
        {
          // 发放管理
          text: '发放管理',
          icon: 'anticon anticon-eye',
          acl: 'Pages.LGModul.Salary.Node',
          link: '/app/linggong/salary',
          sort: 14,
        },
        // {
        //   text: "",
        //   i18n: "完税管理",
        //   acl: "Pages.LingGong",
        //   icon: "anticon anticon-profile",
        //   link: "/app/linggong/taxPayment",
        // },
        {
          // 发放明细
          text: '',
          i18n: '发放明细',
          acl: 'Pages.SalaryDetailsPermissions',
          icon: 'anticon anticon-unordered-list',
          link: '/app/linggong/detail',
        },
        {
          // 工单管理
          text: '',
          i18n: '工单管理',
          acl: 'Pages.WorkOrder',
          icon: 'anticon anticon-profile',
          link: '/app/linggong/workorder',
        },
        {
          // 协议模版管理
          text: '',
          i18n: '协议模版管理',
          acl: 'Pages.AgreementTemplate.Node',
          icon: 'anticon anticon-profile',
          link: '/app/linggong/templeteManage',
        },
        {
          // 发票
          text: '',
          i18n: '发票',
          acl: 'Pages.ReceiptList',
          icon: 'anticon anticon-profile',
          link: '/app/linggong/receipt',
        },
        {
          // 表单控件管理
          text: '',
          i18n: '表单控件管理',
          acl: 'Pages.LGModul.FieldResource.Node',
          icon: 'anticon anticon-profile',
          link: '/app/linggong/fieldlib',
        },
        {
          // 保险数据管理
          link: '/app/linggong/insurance',
          text: '保险数据管理',
          acl: 'Pages.LGModule.InsurancePurchaseData.Node',
          icon: 'anticon anticon-menu',
          sort: 14,
        },
        {
          link: '/app/linggong/exportTask',
          text: '导出任务',
          acl: 'Pages.ExportTask.Node',
          icon: 'anticon anticon-profile',
          sort: 15,
        },
        {
          // 报备单管理
          text: '',
          i18n: '报备单管理',
          acl: 'Pages.SohoIndustry.Node',
          icon: 'anticon anticon-profile',
          link: '/app/linggong/soho',
        },
        {
          // 报备单管理
          text: '',
          i18n: '个体户注销申请',
          acl: 'Pages.Termination.Node',
          icon: 'anticon anticon-menu',
          link: '/app/linggong/termination',
        },
        {
          // 三方开票申请
          link: "/app/linggong/tpsInvoice",
          text: "三方开票申请",
          acl: "Pages.TPS_Invoice",
          icon: 'anticon anticon-profile',
        },
        {
          // 新经济个体工商户管理
          link: "/app/linggong/individualBusiness",
          text: "新经济个体工商户管理",
          acl: "Pages.IndividualBusiness",
          icon: 'anticon anticon-profile',
        },

        // {
        //   // 保险数据管理
        //   link: "/app/linggong/test",
        //   text: "test",
        //   acl: "Pages.LGModule.InsurancePurchaseData.Node",
        //   icon: "anticon anticon-menu",
        // },
        // {
        //     text: "人员花名册",
        //     icon: "anticon anticon-user",
        //     acl: 'Pages.LGModul.PersonnelRoster.Node',
        //     link: "/app/linggong/roster",
        //     sort: 14
        // }
      ],
    },
    {
      // 非自然人业务
      text: '',
      i18n: '非自然人业务',
      acl: 'Pages.Unnatural',
      icon: 'anticon anticon-user',
      children: [
        {
          // 项目人员管理
          text: '',
          i18n: '项目人员管理',
          acl: 'Pages.UnnaturalProjectPersonnelPermissions',
          icon: 'anticon anticon-user',
          link: '/app/unnaturalperson/projectPersonel',
        },
        {
          // 人员管理
          text: '',
          i18n: '人员管理',
          acl: 'Pages.UnnaturalPersonnelPermissions',
          icon: 'anticon anticon-user',
          link: '/app/unnaturalperson/personnel',
        },
        {
          // 项目管理
          text: '',
          i18n: '项目管理',
          icon: 'anticon anticon-project',
          acl: 'Pages.UnnaturalProjectPermissions',
          link: '/app/unnaturalperson/project',
        },
        {
          // 协议类型管理
          text: '',
          i18n: '项目协议管理',
          icon: 'anticon anticon-ant-design',
          acl: 'Pages.UnnaturalProjectAgreementPermissions',
          link: '/app/unnaturalperson/agreementtypemanage',
        },
        {
          // 协议类型管理new
          text: '',
          i18n: '项目协议管理(New)',
          icon: 'anticon anticon-ant-design',
          acl: 'Pages.UnnaturalProjectAgreementV2Permissions',
          link: '/app/unnaturalperson/agreementtypev2',
        },
        {
          // 协议签署管理
          text: '',
          i18n: '协议签署管理',
          icon: 'anticon anticon-ant-design',
          acl: 'Pages.UnnaturalPersonAgreementTemplate',
          link: '/app/unnaturalperson/agreementsignmanage',
        },
        {
          text: '',
          i18n: '表单管理',
          icon: 'anticon anticon-form',
          acl: 'Pages.UnnaturalProjectFormPermissions',
          link: '/app/unnaturalperson/collectionform',
        },
        {
          // 协议模版管理
          text: '',
          i18n: '协议模版管理',
          acl: 'Pages.UnnaturalAgreementTemplatePermissions',
          icon: 'anticon anticon-laptop',
          link: '/app/unnaturalperson/templeteManage',
        },
        {
          // 网鱼网咖门店管理
          text: '',
          i18n: '网鱼网咖门店管理',
          acl: 'Pages.WanyooStore',
          icon: 'anticon anticon-laptop',
          link: '/app/unnaturalperson/wanyooStore',
        },
        {
          // 美团优选-人员签署管理
          text: '',
          i18n: '人员签署管理',
          acl: 'Pages.PersonSignManager',
          icon: 'anticon anticon-solution',
          link: '/app/unnaturalperson/personsignmanage',
        },
        {
          // 美团优选-签署信息校对
          text: '',
          i18n: '签署信息校对',
          acl: 'Pages.SignInfoCheck',
          icon: 'anticon anticon-menu',
          link: '/app/unnaturalperson/checksignmanage',
        },
        {
          // 美团优选-保险购买记录
          text: '',
          i18n: '保险购买记录',
          acl: 'Pages.InsuranceLog.Page',
          icon: 'anticon anticon-ordered-list',
          link: '/app/unnaturalperson/insurancelog',
        },
      ],
    },
    // {
    //     text: 'Books',
    //     i18n: 'Books',
    //     acl: 'Pages.Books.Book',
    //     icon: 'anticon anticon-dashboard',
    //     link: '/app/main/books/book'
    // },
    // {
    // 演示ui
    //   text: "",
    //  i18n: "DemoManagement",
    //  icon: "anticon anticon-info-circle",
    //  link: "/app/demo/demoui",
    //},
    //{
    // 网站设置
    //text: "",
    //i18n: "WebSiteSettingModule",
    //icon: "iconfont icon-dashboard",
    //children: [
    //{
    // 	轮播图广告的菜单按钮
    // text: "BannerAd",
    //  i18n: "BannerAd",
    //  acl: "Pages.BannerAd",
    //  icon: "iconfont icon-dashboard",
    //  link: "/app/website/bannerads",
    //  sort: 1,
    //  },
    //  {
    // 	友情链接分类的菜单按钮
    // text: "BlogrollType",
    // i18n: "BlogrollType",
    // acl: "Pages.BlogrollType",
    //  icon: "iconfont icon-dashboard",
    ///  link: "/app/website/blogrollstype",
    //  sort: 2,
    // },
    // {
    // 	友情链接分类的菜单按钮
    //  text: "Blogroll",
    ///  i18n: "Blogroll",
    //  acl: "Pages.Blogroll",
    //   icon: "iconfont icon-dashboard",
    // link: "/app/website/blogrolls",
    //  sort: 3,
    // },
    // {
    // 	网站公告的菜单按钮
    //  text: "WebSiteNotice",
    // i18n: "WebSiteNotice",
    //  acl: "Pages.WebSiteNotice",
    //  icon: "iconfont icon-dashboard",
    //  link: "/app/website/notices",
    //  sort: 4,
    // },
    // ],
    //  },
    //d {
    // 	 博客的菜单按钮
    // text: "",
    // i18n: "Blog",
    // acl: "Pages.Blog",
    // icon: "iconfont icon-dashboard",
    // link: '/app/blogging/blogs',
    //sort: 6,
    //children: [
    //
    // {
    // 组织机构
    //   text: "",
    //   i18n: "Blog",
    ///    acl: "Pages.Blog",
    //    icon: "iconfont icon-team",
    //   link: "/app/blogging/blogs",
    //   sort: 1,
    // },
    // {
    // 	文章的菜单按钮
    //  text: "Post",
    //  i18n: "Post",
    //  acl: "Pages.Post",
    //  icon: "iconfont icon-dashboard",
    //  link: "/app/blogging/posts",
    //  sort: 2,
    // },
    // {
    // 	文章的菜单按钮
    // text: "tese",
    // i18n: "Post",
    //  acl: "Pages.Post",
    //  icon: "iconfont icon-dashboard",
    // link: "/app/blogging/editor",
    // sort: 4,
    // },
    // ToDo 先不做
    // {
    //     // 	评论的菜单按钮

    //     text: 'Comment',
    //     i18n: 'Comment',
    //     acl: 'Pages.Comment',
    //     icon: 'iconfont icon-dashboard',
    //     link: '/app/blogging/comments',
    //     sort: 99,
    // },
    //  {
    // 	标签的菜单按钮
    //      text: 'Tag',
    //     i18n: 'Tag',
    //      acl: 'Pages.Tag',
    //      icon: 'iconfont icon-dashboard',
    //      link: '/app/blogging/tagging',
    //      sort: 99,
    //  },
    //  ],
    //},
    {
      // 电子协议
      text: '',
      i18n: '电子协议',
      acl: 'Pages.Agreement',
      icon: 'anticon anticon-menu',
      children: [
        {
          // 协议通用字段管理
          text: '',
          i18n: '协议分类管理',
          acl: 'Pages.AgreementCategory',
          icon: 'anticon anticon-cluster',
          link: '/app/agreement/agreementCategoryFields',
        },
        {
          // 协议内容通用字段管理
          text: '',
          i18n: '通用字段管理',
          acl: 'Pages.GeneralFieldsOfAgreementContentGroup',
          icon: 'anticon anticon-tags',
          link: '/app/agreement/generalFields',
        },
        {
          // 协议类型管理
          text: '',
          i18n: '协议模版管理',
          acl: 'Pages.AgreementTemplate',
          icon: 'anticon anticon-laptop',
          link: '/app/agreement/category',
        },
        // {
        //   // 印章管理
        //   text: "",
        //   i18n: "印章管理",
        //   // acl: "",
        //   icon: "anticon anticon-safety",
        //   link: "/app/agreement/seal",
        // },
      ],
    },
    {
      text: '',
      i18n: '培训管理',
      acl: 'Pages.Train',
      icon: 'anticon anticon-book',
      children: [
        {
          // 培训管理
          text: '',
          i18n: '培训管理',
          acl: 'Pages.TrainPermissions',
          icon: 'anticon anticon-bell',
          link: '/app/train/train',
        },
        {
          // 试题管理
          text: '',
          i18n: '试题管理',
          acl: 'Pages.TestPermissions',
          icon: 'anticon anticon-container',
          link: '/app/train/exam',
        },
        {
          // 调研问卷
          text: '',
          i18n: '调研问卷',
          icon: 'anticon anticon-ant-design',
          acl: 'Pages.Questionnaire',
          link: '/app/unnaturalperson/questionnaire',
        },
      ],
    },
    {
      text: '',
      i18n: '财务管理',
      acl: 'Pages.Financial',
      icon: 'anticon anticon-ordered-list',
      children: [
        {
          // 审核发放
          text: '',
          i18n: '审核发放',
          acl: 'Pages.SalaryPermissions',
          icon: 'anticon anticon-plus',
          link: '/app/finanical/issue',
        },
        {
          // 发放汇总
          text: '',
          i18n: '发放汇总',
          acl: 'Pages.SalarySumPermissions',
          icon: 'anticon anticon-ordered-list',
          link: '/app/finanical/statistical',
        },
        {
          // 发票管理
          text: '',
          i18n: '发票管理',
          acl: 'Pages.InvoicePermissions',
          icon: 'anticon anticon-pay-circle',
          link: '/app/finanical/receipt',
        },

        {
          // 财务退款
          text: '',
          i18n: '财务退款',
          acl: 'Pages.FinancialRejectPermissions',
          icon: 'anticon anticon-transaction',
          link: '/app/finanical/refund',
        },
        {
          // 财务退款
          text: '',
          i18n: '账单',
          acl: 'Pages.FinancialBillPermissions',
          icon: 'anticon anticon-transaction',
          link: '/app/finanical/viewbill',
        },
      ],
    },
    {
      // 系统管理
      text: '工作流',
      i18n: '工作流',
      acl: 'Pages.Workflow',
      icon: 'anticon anticon-fork',
      link: '/app/workflow/index',
      children: [
        {
          text: '流程模板管理',
          i18n: '流程模板管理',
          acl: 'Pages.WorkflowSchemeInfo',
          icon: 'anticon anticon-dashboard',
          link: '/app/workflow/management/index',
        },
        {
          text: '流程任务',
          i18n: '流程任务',
          acl: 'Pages.WorkflowTask',
          icon: 'anticon anticon-dashboard',
          link: '/app/workflow/task',
        },
        {
          text: '流程监控',
          i18n: '流程监控',
          acl: 'Pages.WorkflowTask',
          icon: 'anticon anticon-dashboard',
          link: '/app/workflow/monitor',
        },
      ],
    },
    {
      // 系统管理
      text: '系统管理',
      i18n: '系统管理',
      acl: 'Pages.System',
      icon: 'anticon anticon-setting',
      children: [
        {
          text: '数据字典',
          i18n: '数据字典',
          acl: 'Pages.DataItem',
          icon: 'anticon anticon-number',
          link: '/app/systemManagement/dataItem',
        },
        {
          text: '接口镜像管理',
          i18n: '接口镜像管理',
          acl: 'Pages.SysInterface',
          icon: 'anticon anticon-api',
          link: '/app/systemManagement/sysInterface',
        },
        {
          text: '业务表单',
          icon: 'anticon anticon-form',
          acl: 'Pages.FormSchemeInfo',
          link: '/app/ZrCustomizeForm/index',
          sort: 2,
        },
        {
          // 语言
          text: '',
          i18n: 'Languages',
          acl: 'Pages.Administration.Languages',
          icon: 'anticon anticon-global',
          link: '/app/admin/languages',
        },
        {
          // 审计日志
          text: '',
          i18n: 'AuditLogs',
          acl: 'Pages.Administration.AuditLogs',
          icon: 'anticon anticon-book',
          link: '/app/admin/auditLogs',
        },
        {
          // 文件管理
          text: '',
          i18n: 'FileManager',
          acl: undefined,
          icon: 'anticon anticon-book',
          link: '/app/admin/file-manager',
        },
        {
          // 宿主机器设置/维护
          text: '',
          i18n: 'Maintenance',
          acl: 'Pages.Administration.Host.Maintenance',
          icon: 'anticon anticon-setting',
          link: '/app/admin/maintenance',
        },
        // {
        //     // 租户设置
        //     text: '',
        //     i18n: 'Settings',
        //     acl: 'Pages.Administration.Tenant.Settings',
        //     icon: 'anticon anticon-setting',
        //     link: '/app/admin/tenant-settings'
        // },
        {
          // 宿主设置
          text: '',
          i18n: 'Settings',
          acl: 'Pages.Administration.Host.Settings',
          icon: 'anticon anticon-setting',
          link: '/app/admin/host-settings',
        },
        {
          // 版本
          text: '',
          i18n: 'Editions',
          acl: 'Pages.Editions',
          icon: 'anticon anticon-layout',
          link: '/app/admin/editions',
        },
        {
          // 意见反馈
          text: '意见反馈',
          icon: 'anticon anticon-mail',
          acl: 'Pages.LGModul.Suggest.Node',
          link: '/app/linggong/feedback',
          sort: 9,
        },
        {
          // 广告设置
          text: '广告设置',
          icon: 'anticon anticon-notification',
          acl: 'Pages.LGModul.Advertising.Node',
          link: '/app/linggong/advertisingconfig',
          sort: 9,
        },
        {
          // 数据库字典
          text: '数据库字典',
          icon: 'anticon anticon-notification',
          acl: 'Pages.DataSourceDic',
          link: '/app/systemManagement/dataSourceDic',
          sort: 10,
        },
        {
          // 数据库字典
          text: '数据库ER',
          icon: 'anticon anticon-notification',
          acl: 'Pages.DataSourceDic',
          link: '/app/systemManagement/er',
          sort: 11,
        },
      ],
    },
    {
      // 数据中心
      text: '数据中心',
      i18n: '数据中心',
      acl: 'Pages.DataCenter',
      icon: 'anticon anticon-area-chart',
      children: [
        {
          text: '签署费用统计(自然人)',
          i18n: '签署费用统计(自然人)',
          acl: 'Pages.SigningFeePermissions',
          icon: 'anticon anticon-sliders',
          link: '/app/datacenter/signingfee',
        },
        {
          text: '签署费用统计(非自然人)',
          i18n: '签署费用统计(非自然人)',
          acl: 'Pages.UnnaturalSigningFeePermissions',
          icon: 'anticon anticon-sliders',
          link: '/app/datacenter/unnaturalsigningfee',
        },
        {
          text: '开票数据统计',
          i18n: '开票数据统计',
          acl: 'Pages.InvoicingPermissions',
          icon: 'anticon anticon-sliders',
          link: '/app/datacenter/invoicing',
        },
        {
          text: '签约公司开票统计',
          i18n: '签约公司开票统计',
          acl: 'Pages.CompanyInvoicPermissions',
          icon: 'anticon anticon-sliders',
          link: '/app/datacenter/companyInvoice',
        },
        {
          text: '任务分享统计',
          i18n: '任务分享统计',
          acl: 'Pages.TaskSharePermissions',
          icon: 'anticon anticon-sliders',
          link: '/app/datacenter/taskshare',
        },
        {
          text: '短信用量统计',
          i18n: '短信用量统计',
          acl: 'Pages.SmsUsagePermissions',
          icon: 'anticon anticon-sliders',
          link: '/app/datacenter/smsusage',
        },
        {
          text: '发放明细统计',
          i18n: '发放明细统计',
          acl: 'Pages.DataCenter.SalaryPayNode',
          icon: 'anticon anticon-sliders',
          link: '/app/datacenter/salarypay',
        },
      ],
    },
    // {
    //     // 关于我们
    //     text: "",
    //     i18n: "About",
    //     icon: "anticon anticon-history",
    //     link: "/app/main/about",
    // },
    // {
    //     // 实时聊天
    //     text: "实时聊天",
    //     icon: "anticon anticon-message",
    //     link: "/app/other/chat",
    //     sort: 7,
    // },
  ];
}
