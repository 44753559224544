/*
 * author zr
 * date 2019-11-20
 */
import ZrFormDesign from "./index.vue";

ZrFormDesign.install = function(Vue) {
  Vue.component(ZrFormDesign.name, ZrFormDesign);
};

export default ZrFormDesign;
