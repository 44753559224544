<template>
  <a-dropdown class="alain-pro__nav-item" placement="bottomRight">
    <a-menu slot="overlay" style="width: 200px">
      <a-menu-item @click="backToMyAccount()" v-if="isImpersonatedLogin">
        <i class="anticon anticon-rollback mr-sm"></i>
        {{ l("BackToMyAccount") }}
      </a-menu-item>
      <a-menu-item @click="changePassword()">
        <i class="anticon anticon-ellipsis mr-sm"></i>
        {{ l("ChangePassword") }}
      </a-menu-item>
      <a-menu-item @click="showLoginAttempts()">
        <i class="anticon anticon-bars mr-sm"></i>
        {{ l("LoginAttempts") }}
      </a-menu-item>
      <a-menu-item @click="changeMySettings()">
        <i class="anticon anticon-setting mr-sm"></i>
        {{ l("MySettings") }}
      </a-menu-item>
      <a-divider />
      <a-menu-item @click="logout()">
        <i class="anticon anticon-logout mr-sm"></i>
        {{ l("退出登录") }}
      </a-menu-item>
    </a-menu>

    <div class="alain-pro__nav-item d-flex align-items-center px-sm">
      <a-avatar class="mr-sm" size="small" src="/assets/images/user.png">
      </a-avatar>
      {{ loginUserName }}
    </div>
  </a-dropdown>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { appAuthService, appSessionService, abpService } from "@/shared/abp";
import {
  ChangePasswordModal,
  LoginAttemptsModal,
  MySettingsModal,
} from "../../profile";
import { ModalHelper } from "@/shared/helpers";
import { AppConsts } from "@/abpPro/AppConsts";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  name: "header-user",
  mixins: [AppComponentBase],
  data() {
    return {
      showNotificationSettings: false,
      commonService: null,
      tentantInfo: {
        id: "",
        sysTenantId: "",
        systemName: "裕米灵工",
        systemHeadImage: "/assets/images/user.png",
        tenantLoinLogo: "https://static.dianmi-north.cn/dm_logo.png",
        tenantAboutUsLink: "",
        tenantPrivacyLink: "",
        tanantClauseLink: "",
        productionCompanyName: "点米",
        productionCompanyWebAdress: "http://www.dianmi365.com/",
      },
    };
  },
  computed: {
    isImpersonatedLogin() {
      return appSessionService.user.impersonatorUserId > 0;
    },
    loginUserName() {
      if (appSessionService.getShownRealName()) {
        return `${appSessionService.getShownLoginName()}/${appSessionService.getShownRealName()}`
      } else {
        return appSessionService.getShownLoginName();
      }
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);

    let tentantName = appSessionService.getShownTentantName();
    if (tentantName && tentantName !== "") {
      let info = sessionStorage.getItem("tentantInfo");
      if (info) {
        let tentantInfo = JSON.parse(info);
        // console.log("tentantInfo:::", tentantInfo);
        this.tentantInfo = {
          ...tentantInfo,
          systemHeadImage: `${AppConsts.uploadBaseUrl}${tentantInfo.systemHeadImage}`,
          tenantLoinLogo: `${AppConsts.uploadBaseUrl}${tentantInfo.tenantLoinLogo}`,
        };
      } else {
        this._getByTentantName(tentantName);
      }
    }
  },
  mounted() {
    console.log(appSessionService.getShownRealName())
  },
  methods: {
    async _getByTentantName(newTenancyName) {
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/SysInterface/GetByTentantName",
          params: {
            tentantName: newTenancyName,
          },
        });
        if (res) {
          sessionStorage.setItem("tentantInfo", JSON.stringify(res));
        }
      } catch (error) {
        console.log(error);
      }
    },
    changePassword() {
      ModalHelper.create(ChangePasswordModal).subscribe((result) => {
        if (result) {
          this.logout();
        }
      });
    },
    showLoginAttempts() {
      ModalHelper.create(LoginAttemptsModal).subscribe(() => {});
    },
    changeMySettings() {
      ModalHelper.create(MySettingsModal).subscribe(() => {});
    },
    backToMyAccount() {},
    logout() {
      sessionStorage.clear("tentantInfo");
      // abpService.abp.multiTenancy.setTenantIdCookie(undefined);
      appAuthService.logout();
    },
  },
};
</script>

<style lang="less" scoped>
.ant-divider-horizontal {
  margin: 4px 0px;
}
</style>
