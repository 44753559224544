<template>
  <a-modal
    title="预览"
    :visible="visible"
    @ok="handleGetData"
    @cancel="handleCancel"
    okText="获取数据"
    cancelText="关闭"
    style="top:20px;"
    :destroyOnClose="true"
    :width="`${previewWidth}px`"
  >
    <zr-form-build :value="jsonData" @submit="handleSubmit" ref="ZrFormBuild" />
    <jsonModel ref="jsonModel" />
  </a-modal>
</template>
<script>
/*
 * author zr
 * date 2019-11-20
 */
import jsonModel from "../ZRFormDesign/module/jsonModal";

import  ZrFormBuild  from "../ZRFormBuild/index"

export default {
  name: "ZrFormPreview",
  data() {
    return {
      visible: false,
      previewWidth: 850,
      jsonData: {}
    };
  },
  components: {
    jsonModel,
    ZrFormBuild
  },
  methods: {
    handleSubmit(p) {
      p.then(res => {
        console.log(res, "获取数据成功");
        this.$refs.jsonModel.jsonData = res;
        this.$refs.jsonModel.visible = true;
      }).catch(err => {
        console.log(err, "获取数据失败");
      });
    },
    handleGetData() {
       console.log(this.$refs)
      this.$refs.ZrFormBuild.getData()
        .then(res => {
          console.log(res, "获取数据成功");
          this.$refs.jsonModel.jsonData = res;
          this.$refs.jsonModel.visible = true;
        })
        .catch(err => {
          console.log(err, "获取数据失败");
        });
    },
    handleCancel() {
      this.visible = false;
    }
  }
};
</script>
