<template>
  <div class="react-table">
    <div id="rtw">
      <slot></slot>
      <!-- table -->
      <div class="t-w" :style="{ minHeight: '100px' }">
        <div class="my-md" v-if="!hideRowSelection && showRowSelectionTips">
          <a-alert :type="'info'" :showIcon="true" style="margin-top: 10px">
            <template slot="message">
              <span
                v-html="l('GridSelectedXItemsTips', selectedRowKeys.length)"
              ></span>
              <a @click="clearData()" class="ml-md">{{ l("ClearEmpty") }}</a>
              <a-divider type="vertical"></a-divider>
              <a @click="refreshData()">{{ l("Refresh") }}</a>
            </template>
          </a-alert>
        </div>
        <div style="margin-bottom: 10px"></div>
        <a-row>
          <!--  :loading="spinning" -->
          <a-table
            id="ltw"
            class="list-table"
            :size="size"
            @change="sortData"
            :pagination="false"
            :rowSelection="hideRowSelection ? null : initRowSelection()"
            :scroll="initScroll"
            :columns="myColumns"
            :rowKey="rowKeyProvider"
            :data-source="tableData"
            :customRow="customRow"
            :bordered="bordered"
          >
            <!-- action list -->
            <span
              class="fn-w"
              slot="actions"
              slot-scope="text, record"
              v-if="actionsType.isShow"
            >
              <template>
                <a
                  class="table-edit"
                  :key="'_a' + index"
                  v-for="(item, index) in actionsType.fns.detailList"
                  style="margin-right: 12px"
                >
                  <span
                    @click="emitItem(item, record)"
                    v-if="
                      actionsType &&
                      actionsType.fns &&
                      actionsType.fns.detailList.length &&
                      item.granted &&
                      (!item.showFunc ||
                        (typeof item.showFunc === 'function' &&
                          item.showFunc(record)))
                    "
                  >
                    <a-icon :type="item.icon" />
                    {{ item.name }}
                  </span>
                </a>
              </template>

              <template>
                <!-- 这是下拉列表 -->
                <!-- :trigger="['click']"  -->
                <a-dropdown
                  v-if="
                    actionsType &&
                    actionsType.fns &&
                    actionsType.fns.dropList.filter((v) => v.granted).length &&
                    (actionsType.fns.dropList.filter((v) => v.granted).length >
                      2 ||
                      actionsType.fns.isShow == true)
                  "
                >
                  <a
                    class="ant-dropdown-link"
                    @click="(e) => e.preventDefault()"
                  >
                    操作 <a-icon type="down" />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item
                      :key="'_b' + i"
                      v-for="(item, i) in actionsType.fns.dropList"
                    >
                      <div
                        v-if="
                          typeof item.showFunc != 'function' ||
                          (typeof item.showFunc == 'function' &&
                            item.showFunc(record))
                        "
                      >
                        <div
                          class="drop-item"
                          v-if="item.granted && !item.delete"
                          @click="emitItem(item, record)"
                        >
                          <a-icon :type="item.icon" />
                          {{ item.name }}
                        </div>
                        <!--delete-->
                        <a-popconfirm
                          placement="top"
                          v-if="item.delete && item.granted"
                          :okText="l('Ok')"
                          :cancelText="l('Cancel')"
                          @confirm="emitItem(item, record)"
                        >
                          <template slot="title">
                            {{
                              item.tipsDesc
                                ? item.tipsDesc
                                : l("ConfirmDeleteWarningMessage")
                            }}
                          </template>
                          <div class="drop-item">
                            <a-icon :type="item.icon ? item.icon : 'delete'" />
                            {{ item.name }}
                          </div>
                        </a-popconfirm>
                      </div>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </template>

              <template>
                <a
                  class="table-edit"
                  :key="'_c' + i"
                  v-for="(_item, i) in actionsType.fns.dropList"
                >
                  <span
                    @click="emitItem(_item, record)"
                    v-if="
                      actionsType &&
                      actionsType.fns &&
                      actionsType.fns.dropList.filter((v) => v.granted)
                        .length &&
                      actionsType.fns.dropList.filter((v) => v.granted)
                        .length <= 2 &&
                      !actionsType.fns.isShow &&
                      _item.granted
                    "
                  >
                    <a-icon :type="_item.icon" />
                    {{ _item.name }}
                  </span>
                </a>
              </template>

              <!-- 全部展示，非下拉列表 -->
              <!-- <a
                              class="table-edit"
                              :key="i"
                              v-for="(item, i) in actionsType.fns.dropList"
                              @click="emitItem(item, record)"
                            >
                              <span
                                v-if="

                                  actionsType &&
                                  actionsType.fns &&
                                  actionsType.fns.dropList.length &&
                                  item.granted
                                "
                              >
                                <a-icon :type="item.icon" />
                                {{ item.name }}
                              </span>
                            </a> -->
              <!-- delete -->
              <a-popconfirm
                placement="top"
                class="table-delete"
                v-if="actionsType.fns.delete && actionsType.fns.delete.granted"
                :okText="l('Ok')"
                :cancelText="l('Cancel')"
                @confirm="emitItem(actionsType.fns.delete, record)"
              >
                <template slot="title">
                  {{
                    actionsType.fns.delete.tipsDesc
                      ? actionsType.fns.delete.tipsDesc
                      : l("ConfirmDeleteWarningMessage")
                  }}
                </template>
                <a class="table-delete">
                  <a-icon type="delete" />{{ actionsType.fns.delete.name }}
                </a>
              </a-popconfirm>
            </span>
          </a-table>
          <a-pagination
            v-if="!hidePagination"
            class="pagination"
            size="small"
            :current="currentPage"
            :default-page-size="defaultPageSize"
            :total="totalItems"
            showSizeChanger
            :page-size-options="pageSizeOptions"
            :showQuickJumper="false"
            :showTotal="showTotal"
            @change="onPageChange"
            @showSizeChange="showSizeChange"
          />
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import objectHelper from "@/shared/helpers/objectHelper";
// import { ModalHelper } from "@/shared/helpers";

export default {
  name: "react-table",
  mixins: [AppComponentBase],
  props: {
    // spinning: {
    //   type: Boolean,
    //   default: false,
    // },
    rowKeyProvider: {
      type: Function,
      value: (item, index) => {
        // console.log(index);
        return item.id ? item.id : index;
      },
    },
    customRow: {
      type: Function,
      value: (event) => {
        // console.log(event);
        return {
          props: {
            //    xxx... //属性
          },
          on: {
            // 事件
            click: (event) => {}, // 点击行
            dblclick: (event) => {},
            contextmenu: (event) => {},
            mouseenter: (event) => {}, // 鼠标移入行
            mouseleave: (event) => {},
          },
        };
      },
    },
    size: {
      type: String,
      default: "small", //"small",
    },
    reqUrl: {
      type: Object,
      default: null,
    },
    pageSizeOptions: {
      type: Array,
      default() {
        // return ["5", "10", "20","30", "50", "100", "200"];
        return ["30", "40", "50", "100", "200"];
      },
    },
    defaultPageSize: {
      type: Number | String,
      default() {
        return 30;
      },
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    tableKey: {
      type: String,
      default: null,
    },
    tableData: {
      type: Array,
      dafault: [],
    },
    totalItems: {
      type: Number,
      dafault: 0,
    },
    showTotal: {
      type: String | Object,
      dafault: "",
    },
    actionsType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    scroll: {
      type: Object,
      default: () => {
        x: 0;
      },
    },
    columns: {
      type: Array,
      default: () => [],
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    showRowSelectionTips: {
      type: Boolean,
      default: true,
    },
    hideRowSelection: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    minHeight: {
      type: Number,
      default: 0,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    selectType: {
      type: String,
      default: "checkbox",
    },
    customerSelectedRowKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedRowKeys: [], // 所选项index []
      selectedRows: [], // 所选项item []
      curPage: 1,
      myColumns: [],
      rowSelection: {},
      commonService: null,
      dropList: [],
      rtHeight: 138,
      ltHeight: 220,
      modalH:
        window.innerHeight - window.innerHeight * 0.14 < 600
          ? 600
          : window.innerHeight - window.innerHeight * 0.14,
    };
  },
  computed: {
    initScroll() { 
      let y;
      if (this.tableData.length && this.tableData.length <= 10) {
        y = 0;
      } else {
        y = window.innerHeight - (this.hideRowSelection ? 260 : 310);
        // if (this.isFull) {
        //   y = window.innerHeight - (this.hideRowSelection ? 260 : 310);
        // } else {
        //   y = this.modalH - (this.hideRowSelection ? 260 : 310);
        // }
      }
      let s = {
        x: this.scroll ? this.scroll.x : 1000,
        y: y,
      };
      return s;
    },
  },
  created() {
    if (!this.columns || !this.columns.length) {
      this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
      this._getFieldRoleByRoleId();
      // let lockUserField = JSON.parse(localStorage.userFieldRole || "[]");
      // if (lockUserField.length == 0) {
      //   this._getFieldRoleByRoleId();
      // } else {
      //   this.initColumns();
      // }
    } else if (
      !!this.actionsType &&
      objectHelper.countProperties(this.actionsType) > 0
    ) {
      //  console.log('----------------actionsType------------------')
      //  console.log(this.columns)
      //自定义列头的 传递了操作的 但是列头中没有 操作列的 属性补完
      let actionCol = this.columns.find((item) => {
        return item.dataIndex == "actions";
      });
      //  console.log(actionCol)
      if (typeof actionCol == "undefined") {
        this.columns.push({
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "actions" },
        });
      }
    }

    if (this.columns && this.columns.length) {
      let columns = [...this.columns];
      // if (columns[columns.length - 1].dataIndex === 'actions' && !columns[columns.length - 1].width) {
      // columns[columns.length - 1].width = 200
      // }
      if (this.actionsType && this.actionsType.fns) {
        let arr =
          this.actionsType.fns.dropList && this.actionsType.fns.dropList.length
            ? this.actionsType.fns.dropList
            : [];
        let detailList =
          this.actionsType.fns.detailList &&
          this.actionsType.fns.detailList.length
            ? this.actionsType.fns.detailList
            : [];
        if (
          arr.length === arr.filter((v) => !v.granted).length &&
          detailList.length === detailList.filter((v) => !v.granted).length &&
          columns[columns.length - 1].dataIndex === "actions"
        ) {
          this.myColumns = this.evoColumns(
            columns.filter((v) => v.dataIndex !== "actions")
          );
          // this.myColumns = columns.filter(v => v.dataIndex !== 'actions')
        } else {
          this.myColumns = this.evoColumns(columns);
          // this.myColumns = columns
        }
      } else {
        this.myColumns = this.evoColumns(columns);
      }
    }
  },
  mounted() {
    if (!this.hideRowSelection) {
      this.ltHeight = this.ltHeight + 50;
    }
    this.$nextTick(() => {
      let rt = this.$el.querySelector("#rtw");
      let lt = this.$el.querySelector("#ltw");
      if (this.isFull) {
        rt.style.height = window.innerHeight - this.rtHeight - 10 + "px";
        lt.style.height = window.innerHeight - this.ltHeight - 10 + "px";
      } else {
        // rt.style.height = this.modalH - this.rtHeight - 100 + "px";
        // lt.style.height = this.modalH - this.ltHeight - 100 + "px";
      }
    });
  },
  beforeDestroy() {},
  watch: {
    customerSelectedRowKeys(newV, oldV) {
      this.selectedRowKeys = newV;
      // do something
      console.log(newV, oldV);
    },
  },
  methods: {
    initRowSelection() {
      const rowSelection = {
        selectedRowKeys:
          this.customerSelectedRowKeys.length > 0
            ? this.customerSelectedRowKeys
            : this.selectedRowKeys,
        onChange: this.onSelectChange,
        type: this.selectType,
        // getCheckboxProps: record => ({
        //   props: {
        //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //     name: record.name,
        //   },
        // }),
      };
      return rowSelection;
    },
    clearData() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.$emit("emitSelectChange", {
        selectedRowKeys: [],
        selectedRows: [],
      });
    },
    rowClicked(row) {
      this.$emit("emitRowClicked", {
        item: row,
      });
    },
    refreshData() {
      this.$emit("emitRefreshData");
    },
    sortData(pagination, filters, sorter) {
      let order;
      if (sorter && sorter.order === "ascend") {
        order = "ASC";
      } else if (sorter && sorter.order === "descend") {
        order = "DESC";
      } else {
        order = "";
      }
      this.$emit("emitSortData", { columnKey: sorter.columnKey, order: order });
    },
    emitItem(btnFns, data) {
      btnFns.fn(data);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.$emit("emitSelectChange", {
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
      });
    },
    onPageChange(page, pageSize) {
      this.curPage = page;
      this.$emit("emitOnPageChange", {
        page: page,
        skipCount: pageSize,
        pageSize: pageSize,
      });
    },
    showSizeChange(current, size) {
      this.$emit("emitShowSizeChange", {
        current: current,
        size: size,
      });
    },
    initColumns() {
      // console.log(this.tableKey);
      let userFieldRole = JSON.parse(localStorage.userFieldRole);
      let roleFocus = userFieldRole.filter((value, index) => {
        return value.key == this.tableKey;
      });
      let columnsFocus = [];
      if (roleFocus && roleFocus.length > 0) {
        columnsFocus = roleFocus[0].items.filter((value, index) => {
          return value.isCheck === true;
        });
      }
      // console.log(JSON.stringify(columnsFocus))
      let columns = [];
      columnsFocus.map((item) => {
        let myDataIndex;
        let sorterFn = (a, b) => a.length - b.length;
        if (item.key !== "Id" && item.isCheck === true) {
          myDataIndex = item.key.replace(
            item.key[0],
            item.key[0].toLowerCase()
          );
          columns.push({
            title: item.name,
            dataIndex: myDataIndex,
            sorter: true,
            align: "center",
            width: "150",
            scopedSlots: { customRender: item.key },
            sorter: sorterFn,
          });
        }
      });
      if (columns.length) {
        columns.push({
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "actions" },
        });
      }

      this.myColumns = columns;
    },
    evoColumns(arr) {
      let _this = this;
      return arr.map((item) => {
        let customRender = function (text, record, index) {
          let child = _this.$createElement("a-tooltip", {
            domProps: {
              innerHTML: text,
            },
            on: {
              click: function () {},
            },
            style: {},
            attrs: {
              title: text,
            },
          });
          let obj = {
            children: child,
            attrs: {},
            on: {},
          };
          return obj;
        };
        if (item.dataIndex !== "actions") {
          return {
            ...item,
            align: "center",
            ellipsis: true,
            sorter: item.sorter ? item.sorter : false,
            width: item.width ? item.width : "",
            customRender: item.customRender ? item.customRender : customRender,
          };
        } else if (item.dataIndex === "actions") {
          return {
            ...item,
            align: "center",
            fixed: "right",
            width: item.width
              ? item.width
              : this.actionsType.fns &&
                this.actionsType.fns.dropList.length &&
                this.actionsType.fns.dropList.length <= 2
              ? 190
              : 180,
          }; //this.actionsType.fns.dropList
        }
      });
    },
    _getFieldRoleByRoleId() {
      this.spinning = true;
      this.commonService
        .getUserFieldRole()
        .finally(() => {
          this.spinning = false;
        })
        .then((result) => {
          localStorage.userFieldRole = JSON.stringify(result);
          this.initColumns();
        });
    },
  },
};
</script>

<style scoped lang="less">
.react-table {
  padding: 10px;
  background-color: #fff;
}

.t-w {
  // min-height: 450px;
  // background-color: #fff;
  .my-md {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.ant-table-tbody > tr > td {
  max-width: 200px;
  min-width: 100px;
  border-bottom: 0;
  text-align: center !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fn-w {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // .table-edit,
  // .table-delete {
  //   // padding: 0 3px;
  //   // display: inline-block;
  //   // text-align: center;
  // }
  .table-edit {
    span {
      display: inline-block;
      width: 100%;
    }
  }

  .table-delete {
    margin-left: 6px;
    // display: block;
  }
}

.drop-item {
  padding: 5px 12px;

  &:hover {
    color: blue;
  }
}

.ant-dropdown-menu-item {
  padding: 0;
}

.pagination {
  // margin: 10px auto;
  margin-top: 15px;
  text-align: right;
}

.ant-dropdown-link {
  &:hover {
    color: blue;
  }
}

.table-edit,
.table-delete {
  text-align: center;
  // width: 100%;
  &:hover {
    color: blue;
  }

  span {
    margin-left: 3px;
  }
}
</style>
