<template>
  <div class="list-table">
    <!-- 数据部分 -->
    <!-- <div class="my-md">
      <a-alert :type="'info'" :showIcon="true">
        <template slot="message">
          <span v-html="l('GridSelectedXItemsTips', selectedRowKeys.length)"></span>
          <a @click="clearData()" class="ml-md">{{ l("ClearEmpty") }}</a>
          <a-divider type="vertical"></a-divider>
          <a @click="refreshData()">{{ l("Refresh") }}</a>
        </template>
      </a-alert>
    </div> -->
    <!-- table -->
    <a-row>
      <a-table
        class="list-table"
        @change="sortData"
        :pagination="false"
        :rowSelection="null"
        :columns="columns"
        :scroll="scroll"
        :rowKey="(tableDatas) => tableDatas.id"
        :data-source="tableData"
      >
         <span
          slot="actions"
          slot-scope="text, record"
          v-if="actionsType.isShow"
        > 
          <!-- 查看 -->
          <a class="table-edit"
            v-if="actionsType.fns.check && actionsType.fns.check.granted"
            @click="preview(record)"
          >
            <a-icon type="unordered-list" />{{ actionsType.fns.check.name }}
          </a>
          <!-- 修改 -->
          <a class="table-edit"
            v-if="actionsType.fns.edit && actionsType.fns.edit.granted"
            @click="edit(record)"
          >
            <a-icon type="edit" />{{ actionsType.fns.edit.name }}
          </a>
          <!-- 新建 -->
          <a class="table-plus" 
            v-if="actionsType.fns.create && actionsType.fns.create.granted"
           @click="create(record)"
          >
            <a-icon type="plus" />{{ actionsType.fns.create.name }}
          </a>
          <!-- 其他操作 -->
          <a class="table-plus" 
            v-if="actionsType.fns.other && actionsType.fns.other.granted" 
            @click="other(record)"
          >
            <a-icon type="profile" />{{ actionsType.fns.other.name }}
          </a>
          <!-- 删除 -->
          <a-popconfirm class="table-delete"
            placement="top"
            v-if="actionsType.fns.delete && actionsType.fns.delete.granted"
            :okText="l('Ok')"
            :cancelText="l('Cancel')"
            @confirm="deleteItem(record)"
          >
            <template slot="title">
              {{ l("ConfirmDeleteWarningMessage") }}
            </template>
            <a class="table-delete">
              <a-icon type="delete" />{{ actionsType.fns.delete.name }}
            </a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  mixins: [AppComponentBase],
  name: "table-no-page",
  props: [
    "tableData", // 表格数据
    // "totalItems", // 总数
    // "showTotalFun", // 分页组件所需数据
    "actionsType", // 显示那些按钮
    // "tableKey",
    "columns",
    "scroll",
  ],
  data() {
    return {
      selectedRowKeys: [], // 所选项index []
      selectedRows: [], // 所选项item []
      curPage: "",
      // columns: [],
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  methods: {
    clearData() {
      this.selectedRowKeys = [];
    },
    refreshData() {
      this.$emit("emitRefreshData");
    },
    sortData(pagination, filters, sorter) {
      let order;
      if (sorter && sorter.order === "ascend") {
        order = "ASC";
      } else if (sorter && sorter.order === "descend") {
        order = "DESC";
      } else {
        order = "";
      }
      this.$emit("emitSortData", { columnKey: sorter.columnKey, order: order });
    },
    preview(item) {
      this.$emit("emitPreview", { item: item });
    },
    edit(item) {
      this.$emit("emitEdit", { item: item });
    },
    create(item) {
      this.$emit("emitCreate", { item: item });
    },
    deleteItem(item) {
      this.$emit("emitDelete", { item: item });
    },
    other(item) {
      this.$emit("emitOther", { item: item });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.$emit("emitSelectChange", {
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  margin: 10px auto;
  text-align: right;
}
.table-plus,
.table-delete {
  margin-left: 5px;
}
</style>
