//import "rxjs/add/operator/finally";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {FileDto, SwaggerException} from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import {throwError} from "rxjs";
import {UrlHelper} from "../../../helpers";
import {message} from "ant-design-vue";
import {formatInternalValue} from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";

let sysInterfaceApi = {
    instance: apiHttpClient,
    namespaces: "SysInterface",
    async GetInternalServices() {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/GetInternalServices";
        let options_ = {
            method: "GET",
            url: url_,
            headers: {
                Accept: "application/json",
            },
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data.items
    },
    initParams(url, params) {
        if (params.length == 0)
            return url

        url += '?'
        for (let index in params) {
            url += params[index].name + '=' + params[index].value + '&'
        }
        return url.substring(0, url.length - 1)
    },
    async call(url, type, method, headers, params){
        if (type != 'internal') {
            //todo 外部接口模拟实现
            return null
        }
        //todo headers 附加
        let url_ = AppConsts.remoteServiceBaseUrl + url;
        console.log(url)
        console.log(url_)

        let options_ = {
            method: method,
            url:
                method == 'GET' ?
                    this.initParams(url_, params) :
                    url_,
            headers: {
                Accept: "application/json",
            },
        };
        console.log(options_)
        if (method == 'POST') {
            options_.data = params
        }

        const _response = await this.getInstance().request(options_);
        console.log(_response)
        return _response.data
     },
    async simulateRequest(url, type, method, headers, params) {
        if (type != 'internal') {
            //todo 外部接口模拟实现
            return null
        }
        //todo headers 附加
        let url_ = AppConsts.remoteServiceBaseUrl + url;
        console.log(url)
        console.log(url_)

        let options_ = {
            method: method,
            url:
                method == 'GET' ?
                    this.initParams(url_, params) :
                    url_,
            headers: {
                Accept: "application/json",
            },
        };
        console.log(options_)
        if (method == 'POST') {
            options_.data = params
        }

        const _response = await this.getInstance().request(options_);
        console.log(_response)
        return _response.data

    },
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
    getPagedList: async function (params) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/GetPaged";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    async  getById(itemId){

        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/GetEditById";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, {id:itemId}),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    getByCode: async (code) => {

    },
    async save(item) {
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/SaveSysInterface";
        console.log('beformClone')
        var content = objectHelper.deepClone(item)
        console.log(item.params)
        content.params = JSON.stringify(item.params)
        console.log(item.params)
        content.headers = JSON.stringify(item.headers)
        console.log(item.params)
        content.fields = JSON.stringify(item.fields)
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: content
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    }
}

export default sysInterfaceApi