import { RouteConfig } from 'vue-router';
import { LayoutBlock, LayoutBlockKeepAlive } from '@/layout';
import customizeFromRouting from "@/app/systemManagement/customize-form/customizeForm.routing";


const workflowRouting: RouteConfig[] = [
    {
        path: 'workflow',
        meta: { title: "流程应用" },
        component: LayoutBlockKeepAlive,
        redirect: '/app/workflow/management/index',
        children: [
            {
                path: 'management/index',
                component: () => import('./management/index.vue'),
                meta: {title: "流程模板管理"},
                children:[
                    {
                    path: 'form',
                    component: () => import('./management/form.vue'),
                    meta: {title: "创建流程模板"},
                }]
                },{
                path: 'designer',
                component: () => import('./management/designer.vue'),
                meta: {title: "流程模板设计"},
            }, {
                path: 'task',
                component: () => import('./task/index.vue'),
                meta: { title: "流程任务" }
            }
            , {
                path: 'monitor',
                component: () => import('./monitor/index.vue'),
                meta: { title: "流程监控" }
            }
        ]
    }
];

export default workflowRouting;
