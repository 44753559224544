<template>
  <keep-alive>
      <router-view />
  </keep-alive>
</template>

<script>
export default {
  name: "layout-block-keep-alive"
};
</script>

<style scoped>
</style>
