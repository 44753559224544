<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">{{ l("编辑签约账户") }}</span>
        <span v-if="!id">{{ l("创建签约账户") }}</span>
      </div>
    </div>
    <a-form :form="form" @submit="handleSubmit" layout="horizontal">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item :label="l('组织架构')">
            <a-select :placeholder="l('请选择组织架构')" :options="organizationData" showSearch showClear v-decorator="[
              'organizationId',
              {
                rules: [
                  {
                    required: true,
                    message: l('请选择组织架构'),
                  },
                ],
              },
            ]" @change="organizationChange">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('发放账户')">
            <a-select :placeholder="l('请选择发放账户')" :options="accountData" showSearch showClear v-decorator="[
              'customerProviderId',
              {
                rules: [
                  {
                    required: true,
                    message: l('请选择发放账户'),
                  },
                ],
              },
            ]" @change="handleAccountChange">
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item :label="l('所属客户')">
            <a-select :placeholder="l('请选择所属客户')" ref="customerId" :options="customers" showSearch showClear
              v-decorator="[
                'customerId',
                {
                  rules: [
                    {
                      required: true,
                      message: l('请选择所属客户'),
                    },
                  ],
                },
              ]">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('备注')">
            <a-input :placeholder="l('请填写备注')" maxlength="200" v-decorator="['remark']" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item :label="l('手续费率')">
            <a-input-number :default-value="0" :min="0" step="0.01" :max="99" :formatter="value => `${value}%`"
              style="width: 100%;" :parser="value => value.replace('%', '')" :placeholder="l('请输入手续费率')"
              v-decorator="['feeRate']" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('自动扣手续费')">
            <a-switch checked-children="开启" un-checked-children="关闭"
              v-decorator="['autoDeductFee', { valuePropName: 'checked' }]" @change="handleSwitchChange">
            </a-switch>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" :style="`display:${(autoDeduct ? '' : 'none')}`">
        <a-col :span="12">
          <a-form-item :label="l('接收账户')">
            <a-select :placeholder="l('请选择接收手续费账户')" :options="receiveAccountData" showSearch showClear
              v-decorator="['receiveFeeAccountId']">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('匹配来款账户')">
            <a-input :placeholder="l('请填写匹配来款账户')" maxlength="500" v-decorator="['deductFeeAccountNos']"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item :label="l('余额预警')">
            <a-input-number :placeholder="l('请输入预警最低金额')" v-decorator="['warningAmount', {}]" :min="0" :max="99999"
              style="width: 100%;" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('预警邮箱')">
            <a-input :placeholder="l('请输入接收预警信息的邮箱')" v-decorator="['notifyEmailAccounts', {}]"
              :maxlength="200"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col span="24">
          <a-form-item class="btn--container" style="text-align: center">
            <a-button type="button" @click="close()">
              {{ l("Cancel") }}
            </a-button>
            <a-button type="primary" html-type="submit">
              {{ l("Save") }}
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-signaccount",
  data () {
    return {
      spinning: false,
      organizationData: [],
      accountData: [],
      receiveAccountData: [],
      autoDeduct: false,
      currentAccountId: null
    };
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  mounted () {
    this.getOrganizationData();
    this.getData();
  },
  methods: {
    handleSwitchChange (checked, e) {
      this.autoDeduct = checked;
      if (!checked) {
        this.form.setFieldsValue({
          customerProviderId: undefined,
          receiveFeeAccountId: undefined,
          deductFeeAccountNos: ''
        });
      }
    },
    getData () {
      if (this.id) {
        this.spinning = true;
        let url = this.$apiUrl + "/api/services/app/CustomerProvideConfig/GetAccountById";
        let options = {
          params: {
            id: this.id,
          },
          method: "GET",
          url: url,
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        };
        this.$api
          .request(options)
          .then((response) => {
            if (response.status != 200) {
              abp.message.warn(this.l("接口异常，请稍后重试"));
              this.success(false);
              return false;
            }
            let res = response.data;
            if (!res) {
              return false;
            }
            this.entity = res;
            this.currentAccountId = res.customerProvideConfigId;
            console.warn('res', res);
            this.form.setFieldsValue({
              organizationId: res.organizationId,
              customerProviderId: res.customerProvideConfigId,
              customerId: res.customerId,
              remark: res.remark,
              feeRate: res.feeRate * 100,
              autoDeductFee: res.autoDeductFee === 1,
              warningAmount: res.warningAmount,
              notifyEmailAccounts: res.notifyEmailAccounts,
              receiveFeeAccountId: res.receiveFeeAccountId,
              deductFeeAccountNos: res.deductFeeAccountNos
            });
            this.autoDeduct = res.autoDeductFee === 1;
            this.organizationChange(res.organizationId, null);
          })
          .catch((e) => {
            console.error(e);
            abp.message.warn(this.l("DefaultErrorMessage"));
          })
          .finally(() => {
            this.spinning = false;
          });
      }
    },
    handleSubmit (e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (values.autoDeductFee) {
            if (!values.feeRate || values.feeRate <= 0) {
              abp.message.warn('开启了自动扣手续费，则必须填写手续费率')
              return;
            }
            if (values.feeRate >= 100) {
              abp.message.warn('手续费率不能大于或等于100%')
              return;
            }
            if (!values.receiveFeeAccountId || values.receiveFeeAccountId === '') {
              abp.message.warn('开启了自动扣手续费，则必须选择接收账户')
              return;
            }
          }

          this.spinning = true;
          const data = {
            ...values,
            id: this.id ? this.id : null,
            autoDeductFee: values.autoDeductFee ? 1 : 0,
            feeRate: values.feeRate <= 1 ? values.feeRate : (values.feeRate / 100).toFixed(4)
          };
          const options = {
            url: `${this.$apiUrl}/api/services/app/CustomerProvideConfig/SaveAccount`,
            method: 'POST',
            data: data,
            headers: {
              "Content-Type": "application/json-patch+json",
            },
          };
          this.$api.request(options).then(response => {
            if (response.status !== 200) {
              abp.message.error(this.l("服务异常，请稍后重试"));
              return;
            }
            abp.message.success(this.l("保存成功"));
            this.success(true);
          });
        }
      });
    },
    getOrganizationData () {
      if (!this.organizationData || this.organizationData.length <= 0) {
        const options = {
          url: `${this.$apiUrl}/api/services/app/OrganizationUnit/GetAllOrganizationUnitList`,
          method: "GET",
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        };
        this.$api.request(options).then(response => {
          if (response.status !== 200) {
            abp.message.error(this.l("服务异常，请稍后重试"));
            return;
          }
          const res = response.data;
          this.organizationData = res.items.map(it => {
            return {
              ...it,
              label: it.displayName,
              value: it.id
            };
          });
        }).catch(error => {
          console.error(error);
        });
      }
    },
    organizationChange (value, option) {
      if (option) {
        this.form.setFieldsValue({
          customerProviderId: undefined,
          receiveFeeAccountId: undefined,
        });
      }
      const opts = {
        url: `${this.$apiUrl}/api/services/app/CustomerProvideConfig/GetSelectData`,
        method: 'GET',
        params: {
          organizationId: value,
          type: 4
        }
      };
      this.$api.request(opts)
        .then(response => {
          if (response.status !== 200) {
            abp.message.error(this.l("服务异常，请稍后重试"));
            return;
          }
          const res = response.data.map(it => {
            return {
              ...it,
              label: it.text
            }
          });
          this.accountData = res;
          if (this.currentAccountId) {
            this.receiveAccountData = res.filter(it => it.value !== this.currentAccountId);
          } else {
            this.receiveAccountData = res;
          }
        }).catch(e => {
          console.error(e);
        });
    },
    handleAccountChange (value, option) {
      this.receiveAccountData = this.accountData.filter(it => it.value !== value);
      if (this.currentAccountId !== value) {
        this.form.setFieldsValue({
          receiveFeeAccountId: undefined,
        });
      }
      this.currentAccountId = value;
    }
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
