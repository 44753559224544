<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>账户流水</span>
      </div>
    </div>
    <div>
      <ele-table :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true" maxHeight=""
        :actionsType="actionsType" :current-page="pageNumber" :hide-row-selection="true" :pageSizes="pageSizeOptions"
        :handleHeight="100" :pageSize="request.maxResultCount" @emitRefreshData="getData"
        @emitOnPageChange="updatePageChange" @emitShowSizeChange="updateShowSizeChange">
        <a-row :gutter="24">
          <!-- 搜索 -->
          <a-col :xs="{ span: 4 }" :sm="{ span: 4 }" :md="{ span: 4 }" :lg="{ span: 4 }">
            <a-button type="primary" @click="searchData" icon="search">刷新</a-button>
          </a-col>
        </a-row>
      </ele-table>
    </div>
  </a-spin>
</template>

<script>
import EleTable from "@/components/ele-table";
import { ModalComponentBase } from "@/shared/component-base";
import moment from 'moment';

export default {
  mixins: [ModalComponentBase],
  components: {
    EleTable
  },
  name: "create-or-edit-signaccount",
  data () {
    return {
      spinning: false,
      columns: [],
      tableData: [],
      totalItems: 0,
      pageNumber: 1,
      actionsType: null,
      pageSizeOptions: ['10', '20', '50', '100', '200'],
      request: { sorting: "", maxResultCount: 10, skipCount: 0 },
      filterText: '',
    };
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.initColumns();
    this.getData();
  },
  mounted () {
  },
  methods: {
    updatePageChange (info) {
      const { page, skipCount, pageSize } = info;
      this.request.skipCount = skipCount * (page - 1);
      this.request.maxResultCount = pageSize;
      this.getData();
    },
    updateShowSizeChange (info) {
      const { current, size } = info;
      this.request.maxResultCount = size;
      this.request.skipCount = 0;
      this.getData();
    },
    getData () {
      const options = {
        url: `${this.$apiUrl}/api/services/app/CustomerProvideConfig/FeeAutoDeductListByPage`,
        method: 'POST',
        data: {
          ...this.request,
          AccountId: this.id
        }
      };
      this.$api.request(options)
        .then(response => {
          if (response.status !== 200) {
            abp.message.error(this.l("服务异常，请稍后重试"));
            return;
          }
          const res = response.data;
          this.tableData = res.items;
          this.totalItems = res.totalCount;
          this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
        }).catch(e => {
          console.error(e);
          abp.message.warn(this.l("DefaultErrorMessage"));
        })
    },
    searchData () {
      this.request.skipCount = 0;
      this.getData();
    },
    initColumns () {
      this.columns = [
        {
          title: this.l("到账流水"),
          dataIndex: "traceNo",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: "traceNo" },
        },
        {
          title: this.l("到账金额"),
          dataIndex: 'amount',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 90,
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: this.l("手续费率"),
          dataIndex: 'feeRate',
          sorter: false,
          align: 'center',
          ellipsis: true,
          width: 90,
          scopedSlots: { customRender: 'feeRate' },
          type: 'custom',
          customRender (text) {
            return text < 1 ? (text * 100).toFixed(0) + '%' : text + '%';
          }
        },
        {
          title: this.l("扣款账户"),
          dataIndex: 'payerAccountName',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: {
            customRender: 'payerAccountName'
          }
        },
        {
          title: this.l("收款账户"),
          dataIndex: 'receiverAccountName',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: {
            customRender: 'receiverAccountName'
          }
        },
        {
          title: this.l("匹配账号"),
          dataIndex: 'deductFeeAccountNos',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'deductFeeAccountNos' },
        },
        {
          title: this.l("扣费时间"),
          dataIndex: 'creationTime',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'creationTime' },
          type: 'custom',
          customRender (text) {
            return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : text;
          }
        },
        {
          title: this.l("扣费结果"),
          dataIndex: 'success',
          sorter: false,
          align: 'center',
          width: 90,
          ellipsis: true,
          scopedSlots: { customRender: 'success' },
          type: 'tag',
          getColor: (value) => {
            var color = '#2db7f5';
            if (!value) {
              color = "#f50";
            }
            return color;
          },
          getText: (value) => {
            var txt = '失败';
            if (value) {
              txt = '成功';
            }
            return txt
          }
        },
        {
          title: this.l("结果说明"),
          dataIndex: 'deductResponse',
          sorter: false,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'deductResponse' },
        }
      ];
    }
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
