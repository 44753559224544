import sysInterfaceApi from "../../service-proxies/sysInterface/services/sys-interface-proxies";
import entityHelper from "@/shared/helpers/EntityHelper.js"
import objectHelper from "../../helpers/objectHelper";
//容器类
let container = {
     create(){
         return {
             //容器上下文
             content:{
                 //上下文的变量集合
                 variables: []
             },
             interfaces: {},
             //订阅当前上下文一个变量的更新
             subscriptVariable(subscriptor, receivePath, targetContentPath, receiver) {
                 //console.log('subscriptVariable')
                 //console.log(targetContentPath)
                 //console.log(this.content.variables)
                 let targetVariable = this.content.variables.find((item) => {
                     return item.path == targetContentPath
                 })

                 //console.log(targetVariable)
                 if (!!targetVariable) {
                     //目标订阅变量增加一个订阅者
                     targetVariable.subscriptions.push({
                             subscriptor: subscriptor,
                             receivePath: receivePath,
                             receiver: receiver
                         }
                     )
                 }

             },
             get(path){
                  let v = this.getNameSpace('','','global',path)
                  return  this.getByFullPath(v)
             },
             getByFullPath(fullPath){
                 return this.content.variables.find(item =>{
                     return item.path  == fullPath
                 })
             },
             getInterfaceInstanceByOriginId(originId){
                  //console.log('getInterfaceInstanceByOriginId')
                  //console.log(this.interfaces)
                   for (let id in this.interfaces){
                        let curInterface =  this.interfaces[id]
                        if (curInterface.originId == originId){
                             return curInterface
                        }
                    }
                    return null
             },
             //在当前上下文声明一个变量
             declareVariable(path, value, name, source, sourceType) {
                 let that = this
                 let variable = {
                     id: entityHelper.newGuid(),
                     path: path,
                     _value: value,
                     _state: "",
                     _stateDelayer: null,
                     get state() {
                         if (this.value == '' || this.value == null) {
                             return 'null'
                         }
                         return this._state
                     },
                     set state(val) {
                         if (val == 'updated') {
                             this._state = 'updating'
                             if (this._stateDelayer != null) {
                                 clearTimeout(this._stateDelayer)
                                 //console.log(this._stateDelayer)
                             }

                             this._stateDelayer = setTimeout(() => {
                                 this._state = "updated"
                                 for (let i in this.subscriptions) {
                                     let curSubscription = this.subscriptions[i]
                                       //console.log(curSubscription.receivePath)
                                     let  pathArr =  curSubscription.receivePath.split('.')
                                     let pointer = null
                                     let j = 0
                                     try{
                                     for ( j = 0; j < pathArr.length; j++) {

                                         let next =  pathArr[j]
                                         if (next == "container"){
                                             pointer = that
                                             continue;
                                         }

                                          //console.log(pointer)
                                          //console.log(pathArr[next])
                                         pointer =   pointer[next]
                                         if (pathArr.length -  (j+1)==1){
                                             break;
                                         }
                                     }
                                     pointer[pathArr[j+1]] =  this.value
                                     } catch(ex){
                                         //console.log(ex)
                                     }
                                     //console.log('call subscriptor!!')
                                     setTimeout(curSubscription.receiver.bind(this,curSubscription.subscriptor, curSubscription.receivePath, this.value), 50)
                                 }
                             }, 300)

                             setTimeout(()=>{
                                 //通过
                                 this._state =  'stable'
                             },300)
                         }
                     },
                     get value() {
                         return this._value
                     },
                     set value(val) {
                         this._value = val
                         //更新变量状态未已经更新
                         this.state = 'updated'
                     },
                     name: "",
                     source: source,
                     sourceType: sourceType,
                     //绑定
                     subscriptions: []
                 }
                 this.content.variables.push(variable)
                 return variable
             },
             updateVariable(name, path, val) {
                 //console.log('-------------updateVariable--------------')
                 //console.log(path)
                 let targetVariable = this.content.variables.find((item) => {
                     return item.path == path
                 })

                 //console.log(targetVariable)
                 if (!!targetVariable) {
                     targetVariable.value =  val
                 }else {
                     console.log('--------完蛋没有找到要更新的目标变量-----------')
                     console.log(name,path,val)
                 }
             },
             getNameSpace(type,objectId,activedScope,path){
                 let namespace = 'container.content.variables'
                 if (activedScope =='private'){
                     namespace += '.'+type+'_{instance}'
                 }else{
                     namespace += '.global'
                 }
                 namespace = namespace.replace('{instance}',objectId)
                 return namespace + '.'+ path
             },
             async  callInterfaceInstance(instance){
                 let that = this
                 let  result  = await sysInterfaceApi.call(instance.path, instance.type, instance.method, instance.headers, instance.params)
                 let instanceFields = instance.fields
                 for (let i = 0; i < instanceFields.length; i++) {
                     let field = instanceFields[i]
                     //console.log(field)
                     let fieldVal = objectHelper.getVal(result,field.path)
                     //console.log(fieldVal)
                     //console.log(field.path)
                     // 参数存放域  共有域 私有域 避免不同接口返回值 互相渗透的问题
                     that.updateVariable(field.name,that.getNameSpace('interface',instance.id,field.activedScope,field.path),fieldVal)
                 }
                 //更新接口返回值到上下文 
             },
             async createInterfaceInstanceById(id) {
                 //接口镜像定义
                 let interfaceImage = await sysInterfaceApi.getById(id)
                 //接口实例
                 let params = JSON.parse(interfaceImage.params)
                 interfaceImage.headers = JSON.parse(interfaceImage.headers)
                 interfaceImage.fields = JSON.parse(interfaceImage.fields)
                 let interfaceInstance = {}
                 interfaceInstance = {
                     ...interfaceImage
                 }
                 //这是接口实例的Id
                 interfaceInstance.id = entityHelper.newGuid()
                 //这是接口镜像的Id
                 interfaceInstance.originId = id
                 //注册当前接口所有返回值到当前上下文
                 let fields = interfaceImage.fields
                 for (let i = 0; i < fields.length; i++) {
                     let curField = fields[i]
                     //console.log(curField)
                     // 参数存放域  共有域 私有域 避免不同接口返回值 互相渗透的问题
                     this.declareVariable(this.getNameSpace('interface',interfaceInstance.id,curField.activedScope,curField.path),'',curField.name,interfaceInstance.id,'interface')
                 }
                 let interfaceParams = {}
                 //todo 请求头部参数绑定
                 //绑定所有参数当前上下文
                 let that = this
                 //标识位 标识这个接口是否存在变量参数
                 let dynamiced = false
                 for (let i in params) {
                     let curParam = params[i]
                     //如果是变量 绑定到当前上下文
                     interfaceParams[curParam.name] = {
                         ...curParam
                     }

                     if (params[i].paramType == 'dynamic') {
                         dynamiced = true
                         //console.log(curParam)
                         //console.log(curParam.path)
                         this.subscriptVariable(interfaceInstance, 'container.interfaces.' + interfaceInstance.id + '.params.' + curParam.name+'.value', that.getNameSpace('','','',curParam.path), async function (instance, path, val) {
                             //调用接口的逻辑
                             //console.log('参数更新了 该调接口了')
                             //console.log(val)
                             //console.log(path)
                             //console.log(instance)
                             let paramChecked =  true
                             for (let i in instance.params){
                                 let param =  instance.params[i]
                                 if (param.value == '' || !!param.value){
                                     //接口的参数有更新 但是不是全部参数都已经填充完成  不掉起接口
                                     paramChecked = false
                                 }
                             }
                             // let  result  = await sysInterfaceApi.call(instance.path, instance.type, instance.method, instance.headers, instance.params)
                             // let instanceFields = instance.fields
                             // for (let i = 0; i < instanceFields.length; i++) {
                             //     let field = instanceFields[i]
                             //     //console.log(field)
                             //     let fieldVal = objectHelper.getVal(result,field.path)
                             //     //console.log(fieldVal)
                             //     //console.log(field.path)
                             //     // 参数存放域  共有域 私有域 避免不同接口返回值 互相渗透的问题
                             //     that.updateVariable(field.name,that.getNameSpace('interfaces',instance.id,field.activedScope,field.path),fieldVal)
                             //     //console.log(that.content)
                             // }
                             await that.callInterfaceInstance(instance)
                             //更新接口返回值到上下文
                             //console.log(result)
                         })
                     }
                     //如果是常量
                     else if(params[i].paramType == 'constant' ){
                     }
                 }
                 interfaceInstance.params = interfaceParams
                 //在当前上下文声明该接口的返回值
                 this.interfaces[interfaceInstance.id] = interfaceInstance
                 //如果接口不存在变量 当场调用

                  if (!dynamiced){
                      //console.log('如果接口不存在变量 当场调用')
                      await that.callInterfaceInstance(interfaceInstance)
                  }
                 return interfaceInstance
             }
         }
     },
}

export default container