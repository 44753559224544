export class FileDto implements IFileDto {
  fileName: string | undefined;
  fileType: string | undefined;
  fileToken: string | undefined;

  constructor(data?: IFileDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.fileName = _data["fileName"];
      this.fileType = _data["fileType"];
      this.fileToken = _data["fileToken"];
    }
  }

  static fromJS(data: any): FileDto {
    data = typeof data === "object" ? data : {};
    let result = new FileDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["fileName"] = this.fileName;
    data["fileType"] = this.fileType;
    data["fileToken"] = this.fileToken;
    return data;
  }

  clone(): FileDto {
    const json = this.toJSON();
    let result = new FileDto();
    result.init(json);
    return result;
  }
}

export interface IFileDto {
  fileName: string | undefined;
  fileType: string | undefined;
  fileToken: string | undefined;
}

export class SwaggerException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isSwaggerException = true;

  static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true;
  }
}
