import { RouteConfig } from 'vue-router';
import { LayoutBlock, LayoutBlockKeepAlive } from '@/layout';

const projectRouting: RouteConfig[] = [
    {
        path: 'project',
        meta: { title: "系统" },
        component: LayoutBlockKeepAlive,
        redirect: '/app/project',
        children: [
            { path: 'project', component: () => import('./project.vue'), meta: { title: "项目管理" } }
        ]
    },
];

export default projectRouting;
