//import "rxjs/add/operator/finally";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {FileDto, SwaggerException} from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import {throwError} from "rxjs";
import {UrlHelper} from "../../../helpers";
import {message} from "ant-design-vue";
import {formatInternalValue} from "ant-design-vue/lib/vc-tree-select/src/util";

let dataItemApi = {
    instance: apiHttpClient,
    locked: false,
    localStore: {},
    get(code) {
        if (this.locked || this.localStorage == {}) {
            setTimeout(() => {
                return this.get(code)
            }, 100)
        } else {
            if (!!this.localStore[code]) {
                return this.localStore[code].children
            }
            return []
        }
    },
      getOptions(code){
        let items =  this.get(code)
        return this.mapToOption(items)
    },
    mapToOption(array){
        return array.map((item)=>
        {
            return {
                label:item.itemName,
                key:item.id,
                value:item.itemValue,
                title:item.itemName
            }
        })

    },
    loadLocalStore() {
        // console.log('loadLocalStore!!!!!!')
         this.getAllTree({}).then((resp) => {
            let tree = resp.items
            //  console.log(tree)
            for (let item in tree) {
                //数据项布尔开头的自动转换
                if (tree[item].itemCode.indexOf('Bool.')==0){
                      for(let i in  tree[item].children){
                           if (tree[item].children[i].itemValue === "true") {
                               tree[item].children[i].itemValue = true
                           } else {
                               tree[item].children[i].itemValue = false
                           }
                      }
                } else if(tree[item].itemCode.indexOf('Int.')==0){
                    for(let i in  tree[item].children){
                         console.log( tree[item].children[i].itemValue)
                         try{
                         tree[item].children[i].itemValue = parseInt(tree[item].children[i].itemValue)
                         } catch(ex){
                              console.log(ex)
                         }
                    }
                }
              //   console.log(tree[item].itemCode.indexOf('Int.'))
                this.localStore[tree[item].itemCode] =  tree[item]
            }
        }).catch((resp) => {
            message.error('加载数据项失败！')
            console.log(resp)
        })
    }
    ,
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
    getPagedList: async function (params) {

        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/DataItem/GetPaged";

        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        //console.log(options_)
        const _response = await this.getInstance().request(options_);
        //console.log(_response)
        if (_response.status != 200) {
            //console.log('请求异常')
            //console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    getAllTree: async function () {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/DataItem/GetAllTree";
        let options_ = {
            method: "GET",
            url: url_,
            headers: {
                Accept: "application/json",
            },
        };
        //console.log(options_)
        const _response = await this.getInstance().request(options_);
        //console.log(_response)
        if (_response.status != 200) {
            //console.log('请求异常')
            //console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    getTreePaged: async function (params) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/DataItem/GetTreePaged";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        //console.log(options_)
        const _response = await this.getInstance().request(options_);
        //console.log(_response)
        if (_response.status != 200) {
            //console.log('请求异常')
            //console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
      async getList(code,parentId){
         let params = {code: '', parentId: ''}

         if (!!code) {
             params.code = code
         }
         if (!!parentId) {
             params.parentId = parentId
         }
         let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/DataItem/GetList";
         let options_ = {
             method: "GET",
             url: UrlHelper.createGetRequest(url_, params),
             headers: {
                 Accept: "application/json",
             },
         };
         //console.log(options_)
         const _response = await this.getInstance().request(options_);
         //console.log(_response)
         if (_response.status != 200) {
             //console.log('请求异常')
             //console.log(_response)
             throwError(_response.data)
             //todo toast
         }
         return _response.data

     },
     getById: async (itemId) => {


    },
    getByCode: async (code) => {
    },
    async getTreeData(code, parentId) {
        let params = {code: '', parentId: ''}

        if (!!code) {
            params.code = code
        }
        if (!!parentId) {
            params.parentId = parentId
        }
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/DataItem/GetTreeData";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
         const _response = await this.getInstance().request(options_);
        //console.log(_response)
        if (_response.status != 200) {
            //console.log('请求异常')
            //console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    async save(item) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/DataItem/SaveDataItem";
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: item
        };
        //console.log(options_)
        const _response = await this.getInstance().request(options_);
        //console.log(_response)
        if (_response.status != 200) {
            //console.log('请求异常')
            //console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
}

export default dataItemApi