import "@babel/polyfill"; // ie polyfill
import Vue from "vue";
import moment from "moment";
import "./shared/core/use";
import "./shared/utils/use-pipes"; // 应用所有管道(filter)
import root from "./root.vue"; // 主容器组件
import { rootStore } from "./shared/store"; // 全局store
import { AppPreBootstrap } from "./AppPreBootstrap"; // 预启动器
import { rootRouting } from "./router"; // 全局路由
import { authRouteGuard } from "./shared/auth"; // 路由守卫
import "./styles/index.less"; // 全局样式注册
import { appSessionService, permissionService } from "./shared/abp";
import { localizationService } from "./shared/i18n";
import { layoutService, menuService, reuseTabService } from "./shared/common";
import { UserNotificationHelper } from "./shared/helpers";
import { AppMenus } from "@/abpPro/AppMenus";
import { preloaderFinished } from "@/shared/utils";
import { PageHeader } from "@/components"; //全局组件PageHeader
import $ from "jquery";
import BaiduMap from "vue-baidu-map";


import {Table, TableColumn, Pagination, Button, Popover} from 'element-ui'

//json美化工具
import JSONView from "vue-json-viewer";

// import './registerServiceWorker'; // serviceWorker
Vue.use(BaiduMap, {
  ak: "sOB4GT7ud55OLOeUB4mdbf2FpBMMOtzX",
});

Vue.config.productionTip = false;
(window as any).drag = new Vue();

Vue.use(PageHeader);

Vue.use(JSONView);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Popover);

/**
 * 过滤器
 */
Vue.filter("moment", (value, formatString) => {
  formatString = formatString || "YYYY-MM-DD HH:mm:ss";
  return moment(value).format(formatString);
});

AppPreBootstrap.run(() => {
  // 初始化会话信息
  appSessionService.init().then((res) => {
    console.log("环境: ", process.env.NODE_ENV);
    // 初始化路由守卫
    authRouteGuard.init(rootRouting);
    // 初始化复用标签
    reuseTabService.init(rootRouting, localizationService, menuService);
    // 初始化菜单服务
    menuService.initService(localizationService, permissionService);
    // 初始化通知服务
    UserNotificationHelper.init();
    // 启用复用标签
    layoutService.data.reuseTab = true;
    // 初始化菜单
    menuService.add(AppMenus.Menus);

    new Vue({
      router: rootRouting,
      store: rootStore,
      render: (h) => h(root),
      mounted() {
        preloaderFinished();
      },
    }).$mount("#app");
  });
});
