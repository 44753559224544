import localizationService from '../i18n/localization.service';
import abpService from '../abp/abp.service';
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import dataItemApi from "../service-proxies/dataItem/services/dataItem-proxies";


const AppCompoentBase = {
    data() {
        return {
            lodingVal: false,
            savingVal: false
        }
    },
    computed: {
        $apiUrl() { // API 的 访问地址
            return AppConsts.remoteServiceBaseUrl;
        },
        $api() { // API 的 http 实例
            return apiHttpClient;
        },
        $notificationCount() {
            return AppConsts.notificationCount;
        },
        message() {
            return abpService.abp.message;
        },
        notify() {
            return abpService.abp.notify;
        },
        loading: {
            get() {
                return this.lodingVal;
            },
            set(val) {
                this.lodingVal = val;
            }
        },
        saving: {
            get() {
                return this.savingVal;
            },
            set(val) {
                this.savingVal = val;
            }
        }
    },
    methods: {
        options(code) {
            let result = dataItemApi.getOptions(code)
            return result
        },
        getConst(name){
              return AppConsts[name]
        },
        l(key, ...args) {
            return localizationService.l(key, ...args);
        },
        isGranted(permissionName) {
            return abpService.abp.auth.isGranted(permissionName);
        },
        isGrantedAny(permissions) {
            if (!permissions) {
                return false;
            }
            for (const permission of permissions) {
                if (this.isGranted(permission)) {
                    return true;
                }
            }
            return false;
        },
        error(msg){
            console.log('-------------噢我的玉帝报错啦-------------------')
            console.log(msg)
            console.log('---------------------------------------------------')
        },
        info(msg){
            console.log('---------------fyi-------------------')
            console.log(msg)
            console.log('---------------------------------------------------')
        }
    },

};

export default AppCompoentBase;

