<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :scroll="{ x: 800 }"
      :hide-row-selection="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8" type="flex" align="middle">
        <a-col :span="12">
          <a-input-search
            name="filterText"
            @search="changeFilterText()"
            :placeholder="l('SearchWithThreeDot')"
            enterButton
            v-model="name"
          />
        </a-col>
        <a-col :span="8" :offset="4" align="right">
          <a @click="createOrEdit(null, 'create')">
            <a-icon type="plus" /> {{ l("添加印章") }}
          </a>
          <a-divider type="vertical"></a-divider>
          <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
            <a-icon type="reload"
          /></a>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { CommonServiceProxy, initSealType } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";

export default {
  name: "seal",
  mixins: [AppComponentBase],
  props: ["id", "reqKey", "tableKey"],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      sealService: null,
      commonService: null,
      selectedRowKeys: [],
      name: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("名称"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("编号"),
          dataIndex: "number",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.l("状态"),
          dataIndex: "status",
          sorter: false,
          type: "tag",
          align: "center",
          scopedSlots: { customRender: "status" },
          customRender: (text) => {
            if (text == 1) {
              return "启用";
            }
            return "禁用";
          },
        },
        {
          title: this.l("是否默认"),
          dataIndex: "isDefault",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "isDefault" },
          customRender: (text) => {
            if (text == true) {
              return "是";
            }
            return "否";
          },
        },
        {
          title: this.l("类型"),
          dataIndex: "sealType",
          align: "center",
          scopedSlots: { customRender: "sealType" },
          customRender: (text) => {
            if (text == 1) {
              return "证明类";
            }
            if (text == 2) {
              return "法大大";
            }
            return "-";
          },
        },
        {
          title: this.l("排序值"),
          dataIndex: "sort",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "sort" },
        },

        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      actionsType: {},
      getDataParams: [],
      changeOpt: [],
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
  },
  beforeDestroy() {},
  methods: {
    // 拉取数据
    async getData(opt, url) {
      this.spinning = true;
      let params = {
        name: this.name,
        filterText: this.filterText,
        sorting: this.request.sorting,
        skipCount: this.request.skipCount,
        maxResultCount: this.request.maxResultCount,
      };
      if (opt && opt.length) {
        // if (!this.getDataParams.length) this.getDataParams = opt
        this.getDataParams = opt;
        opt.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      } else if (this.getDataParams && this.getDataParams.length) {
        this.getDataParams.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      }
      this.changeOpt = opt;
      try {
        let res = await this.commonService.get({
          url: url
            ? url
            : this.reqKey === "Organization"
            ? "/api/services/app/Organization/GetOrganizationSealPaged"
            : "/api/services/app/Customer/GetCustomerSealPaged",
          params: params,
        });
        this.spinning = false;
        // this.tableData = res.items.map((o) => {
        //   return {
        //     ...o,
        //     isDefaultStr: o.isDefault ? "是" : "否",
        //     statusStr: o.status ? "正常" : "禁用",
        //   };
        // });
        this.tableData = res.items.map((v) => {
          return {
            ...v,
            status: v.status === 1 ? "启用" : "禁用",
            statusColor: v.status === 1 ? "#87d068" : "#f50",
            sealType: initSealType(v.sealType),
            isDefault: v.isDefault ? "是" : "否",
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
      } catch (error) {
        this.spinning = false;
      }
    },
    changeFilterText(value) {
      this.request.skipCount = 0;
      this.filterText = value;
      this.getData(this.changeOpt);
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData(this.changeOpt);
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 删除
    async deleteItem(params) {
      this.spinning = true;
      try {
        let res = await this.commonService.delete({
          url:
            this.reqKey === "Organization"
              ? "/api/services/app/Organization/DeleteOrganizationSeal"
              : "/api/services/app/Customer/DeleteCustomerSeal",
          params: params,
        });
        this.spinning = false;
        this.getData();
        this.$notification["success"]({
          message: this.l("SuccessfullyRemoved"),
        });
        this.clearFilterAndRefresh();
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // 预览
    previewItem(newV) {
      this.createOrEdit(newV.item, "preview");
    },
    // 修改
    editItem(newV) {
      // let { id } = newV.item;
      this.createOrEdit(newV.item, "edit");
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },

    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("查看"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "preview");
              },
            },
            {
              granted: true,
              name: this.l("Edit"),
              icon: "profile",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
            {
              granted: true,
              name: this.l("设为默认"),
              icon: "container",
              fn: (data) => {
                _this._setDefaultSeal(data.id);
              },
            },
          ],
          delete: {
            granted: true,
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    async _setDefaultSeal(id) {
      this.spinning = true;
      try {
        const res = await this.commonService.post({
          url:
            this.reqKey === "Organization"
              ? "/api/services/app/Organization/SetDefaultSeal"
              : "/api/services/app/Customer/SetDefaultSeal",
          params: { id: id },
        });
        this.$message.success("设置成功");
        this.clearFilterAndRefresh();
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _getStatusType(type) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Project/GetEnumNameValueArray",
          params: { enumType: type },
        });
        this.spinning = false;
        return res.map((item) => {
          return {
            label: item.key,
            value: Number(item.value),
          };
        });
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    _getSealTypes() {
      return [
        {
          label: "证明类",
          value: 1,
        },
        {
          label: "法大大",
          value: 2,
        },
      ];
    },
    // 增加修改查看
    async createOrEdit(item, type) {
      const list = [
        {
          label: "名称",
          value: "name",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "编号",
          value: "number",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "排序",
          value: "sort",
          type: "numberInput",
          disabled: false,
          required: true,
          defaultValue: 0,
          // rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        // {
        //   label: '是否默认',
        //   value: 'isDefault',
        //   type: 'switch',
        //   disabled: false,
        //   required: true,
        //   rules: [],
        //   changeFn: () => {}
        // },
        {
          label: "状态",
          value: "status",
          type: "radio",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择状态" }],
          defaultValue: 1,
          defaultSelectList: await this._getStatusType(3),
          changeFn: () => {},
        },
        {
          label: "印章类型",
          value: "sealType",
          type: "radio",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择印章类型" }],
          defaultValue: 1,
          defaultSelectList: this._getSealTypes(),
          changeFn: () => {},
        },
        {
          label: "附件资源",
          value: [],
          type: "upload",
          disabled: false,
          required: true,
          rules: [],
          // listType: "picture-card",
          subdirName: environment.production ? "abpProd" : "abpDev",
          uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
          limitedLength: 1,
          changeFn: (info) => {
            let fileList = [...info.fileList];
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
              }
              return file;
            });
            list[list.length - 1].value = fileList;
            // console.log(this.fileList);
          },
        },
      ];
      let reqParams = [];
      if (item && item.id) reqParams.push({ key: "id", value: item.id });
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [
              ...reqParams,
              {
                key: `${
                  this.reqKey === "Organization"
                    ? "organizationUnitId"
                    : "customerId"
                }`,
                value: this.id,
              },
            ],
            reqUrl: {
              getById:
                this.reqKey === "Organization"
                  ? "/api/services/app/Organization/GetOrganizationSealById"
                  : "/api/services/app/Customer/GetCustomerSealById",
              save:
                this.reqKey === "Organization"
                  ? "/api/services/app/Organization/SaveOrganizationSeal"
                  : "/api/services/app/Customer/SaveCustomerSeal",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({ ...res });
              if (res.pictureUrls && res.pictureUrls.indexOf(",") !== -1) {
                let arr = res.split(",");
                arr.map((item, i) => {
                  let n = item.split("/");
                  list[list.length - 1].value.push({
                    uid: `-${i + 1}`,
                    url: `${AppConsts.uploadBaseUrl}${item}`,
                    name: n[n.length - 1],
                    response: { data: { Path: item } },
                    status: "done",
                  });
                });
              } else if (res.pictureUrls) {
                let n = res.pictureUrls.split("/");
                list[list.length - 1].value.push({
                  uid: `-1`,
                  url: `${AppConsts.uploadBaseUrl}${res.pictureUrls}`,
                  name: n[n.length - 1],
                  response: { data: { Path: res.pictureUrls } },
                  status: "done",
                });
              }
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              if (!list[list.length - 1].value.length) {
                this.$message.info("请上传附件资源");
                return;
              }
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);

              // fileUrlList
              let fileUrlList = [];
              list[list.length - 1].value.map((item) => {
                fileUrlList.push(item.response.data.Path);
              });
              let pictureUrls =
                fileUrlList.length > 1
                  ? fileUrlList.join(",")
                  : `${fileUrlList[0]}`;
              try {
                // console.log({
                //   url: queryParams.reqUrl.save,
                //   params: {...values, pictureUrls: pictureUrls, status: Number(values.status)},
                // })
                await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: {
                    ...values,
                    pictureUrls: pictureUrls,
                    status: Number(values.status),
                    isDefault: false,
                  },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          // this.clearFilterAndRefresh();
          this.getData();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-edit,
  .table-delete {
    margin-left: 3px;
    i {
      padding: 0 2px;
    }
  }
}
.opeattion-container {
  margin: 20px 0;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
