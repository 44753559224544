<template>
<div class="page-header">
    <div class="page-header-index-wide">
        <h2 v-if="false" class="title">{{ title }}</h2>
        <a-breadcrumb>
            <a-breadcrumb-item href="">
                <a-icon type="home" />
            </a-breadcrumb-item>

            <a-breadcrumb-item>
                {{ title }}
            </a-breadcrumb-item>
        </a-breadcrumb>
        <div class="detail" v-if="false">
            <div class="main" v-if="!$route.meta.hiddenHeaderContent">
                <div class="row">
                    <img v-if="logo" :src="logo" class="logo" />
                    <div class="action">
                        <slot name="action"></slot>
                    </div>
                </div>
                <div class="row">
                    <div v-if="avatar" class="avatar">
                        <a-avatar :src="avatar" />
                    </div>
                    <div v-if="this.$slots.content" class="headerContent">
                        <slot name="content"></slot>
                    </div>
                    <div v-if="this.$slots.extra" class="extra">
                        <slot name="extra"></slot>
                    </div>
                </div>
                <div>
                    <slot name="pageMenu"></slot>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Breadcrumb from "@/components/tools/Breadcrumb";
export default {
    name: "PageHeader",
    components: {
        "s-breadcrumb": Breadcrumb,
    },
    props: {
        title: {
            type: [String],
            required: false,
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="less" scoped>
@import "./style/index.less";
</style>
