var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
  !(_vm.record.options.hidden === true) &&
    [
      'input',
      'textarea',
      'date',
      'time',
      'number',
      'radio',
      'checkbox',
      'select',
      'rate',
      'switch',
      'slider',
      'uploadImg',
      'uploadFile',
      'cascader',
      'treeSelect'
    ].includes(_vm.record.type)
)?_c('a-form-item',{style:('display:'+ (_vm.record.options.conditionProofed ? '':'none')),attrs:{"label":_vm.record.label,"label-col":_vm.formConfig.layout === 'horizontal' ? _vm.formConfig.labelCol : {},"wrapper-col":_vm.formConfig.layout === 'horizontal' ? _vm.formConfig.wrapperCol : {}}},[(_vm.record.type === 'input')?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model, // input 的 name
    {
      initialValue: _vm.record.options.defaultValue, // 默认值
      rules: _vm.record.rules // 验证规则
    }
  ]),expression:"[\n    record.model, // input 的 name\n    {\n      initialValue: record.options.defaultValue, // 默认值\n      rules: record.rules // 验证规则\n    }\n  ]"}],style:(("width:" + (_vm.record.options.width))),attrs:{"forceRender":"","disabled":_vm.disabled || _vm.record.options.disabled,"placeholder":_vm.record.options.placeholder,"type":_vm.record.options.type,"allowClear":_vm.record.options.clearable,"maxLength":_vm.record.options.maxLength},on:{"change":function($event){return _vm.handleChange($event.target.value, _vm.record.model)}}}):(_vm.record.type === 'textarea')?_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model, // input 的 name
    {
      initialValue: _vm.record.options.defaultValue, // 默认值
      rules: _vm.record.rules // 验证规则
    }
  ]),expression:"[\n    record.model, // input 的 name\n    {\n      initialValue: record.options.defaultValue, // 默认值\n      rules: record.rules // 验证规则\n    }\n  ]"}],style:(("width:" + (_vm.record.options.width))),attrs:{"autoSize":{
    minRows: _vm.record.options.minRows,
    maxRows: _vm.record.options.maxRows
  },"disabled":_vm.disabled || _vm.record.options.disabled,"placeholder":_vm.record.options.placeholder,"allowClear":_vm.record.options.clearable,"maxLength":_vm.record.options.maxLength,"rows":4},on:{"change":function($event){return _vm.handleChange($event.target.value, _vm.record.model)}}}):(_vm.record.type === 'date')?_c('KDatePicker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model, // input 的 name
    {
      initialValue: _vm.record.options.range
        ? _vm.record.options.rangeDefaultValue
        : _vm.record.options.defaultValue, // 默认值
      rules: _vm.record.rules // 验证规则
    }
  ]),expression:"[\n    record.model, // input 的 name\n    {\n      initialValue: record.options.range\n        ? record.options.rangeDefaultValue\n        : record.options.defaultValue, // 默认值\n      rules: record.rules // 验证规则\n    }\n  ]"}],attrs:{"record":_vm.record,"parentDisabled":_vm.disabled},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'time')?_c('KTimePicker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model, // input 的 name
    {
      initialValue: _vm.record.options.defaultValue, // 默认值
      rules: _vm.record.rules // 验证规则
    }
  ]),expression:"[\n    record.model, // input 的 name\n    {\n      initialValue: record.options.defaultValue, // 默认值\n      rules: record.rules // 验证规则\n    }\n  ]"}],attrs:{"record":_vm.record,"parentDisabled":_vm.disabled},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'number')?_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],style:(("width:" + (_vm.record.options.width))),attrs:{"min":_vm.record.options.min || _vm.record.options.min === 0
      ? _vm.record.options.min
      : -Infinity,"max":_vm.record.options.max || _vm.record.options.max === 0
      ? _vm.record.options.max
      : Infinity,"disabled":_vm.disabled || _vm.record.options.disabled,"step":_vm.record.options.step,"precision":_vm.record.options.precision > 50 ||
    (!_vm.record.options.precision && _vm.record.options.precision !== 0)
      ? null
      : _vm.record.options.precision,"placeholder":_vm.record.options.placeholder},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'radio')?_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],attrs:{"options":_vm.options,"disabled":_vm.disabled || _vm.record.options.disabled,"placeholder":_vm.record.options.placeholder},on:{"change":function($event){return _vm.handleChange($event.target.value, _vm.record.model)}}}):(_vm.record.type === 'checkbox')?_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],attrs:{"options":_vm.options,"disabled":_vm.disabled || _vm.record.options.disabled,"placeholder":_vm.record.options.placeholder},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'rate')?_c('a-rate',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],attrs:{"count":_vm.record.options.max,"disabled":_vm.disabled || _vm.record.options.disabled,"placeholder":_vm.record.options.placeholder,"allowHalf":_vm.record.options.allowHalf},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'select')?_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],style:(("width:" + (_vm.record.options.width))),attrs:{"placeholder":_vm.record.options.placeholder,"showSearch":_vm.record.options.showSearch,"options":_vm.options,"disabled":_vm.disabled || _vm.record.options.disabled,"allowClear":_vm.record.options.clearable,"mode":_vm.record.options.multiple ? 'multiple' : ''},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'switch')?_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      valuePropName: 'checked',
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      valuePropName: 'checked',\n      rules: record.rules\n    }\n  ]"}],attrs:{"disabled":_vm.disabled || _vm.record.options.disabled},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'slider')?_c('div',{staticClass:"slider-box",style:(("width:" + (_vm.record.options.width)))},[_c('div',{staticClass:"slider"},[_c('a-slider',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.record.model,
        {
          initialValue: _vm.record.options.defaultValue,
          rules: _vm.record.rules
        }
      ]),expression:"[\n        record.model,\n        {\n          initialValue: record.options.defaultValue,\n          rules: record.rules\n        }\n      ]"}],attrs:{"disabled":_vm.disabled || _vm.record.options.disabled,"min":_vm.record.options.min,"max":_vm.record.options.max,"step":_vm.record.options.step},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}})],1),(_vm.record.options.showInput)?_c('div',{staticClass:"number"},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        _vm.record.model,
        {
          initialValue: _vm.record.options.defaultValue
        }
      ]),expression:"[\n        record.model,\n        {\n          initialValue: record.options.defaultValue\n        }\n      ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabled || _vm.record.options.disabled,"min":_vm.record.options.min,"max":_vm.record.options.max,"step":_vm.record.options.step},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}})],1):_vm._e()]):(_vm.record.type === 'uploadImg')?_c('zr-upload-img',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],style:(("width:" + (_vm.record.options.width))),attrs:{"parentDisabled":_vm.disabled,"record":_vm.record,"config":_vm.config},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'uploadFile')?_c('zr-upload-file',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],style:(("width:" + (_vm.record.options.width))),attrs:{"record":_vm.record,"config":_vm.config,"dynamicData":_vm.dynamicData,"parentDisabled":_vm.disabled},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'treeSelect')?_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],style:(("width:" + (_vm.record.options.width))),attrs:{"placeholder":_vm.record.options.placeholder,"multiple":_vm.record.options.multiple,"showSearch":_vm.record.options.showSearch,"treeCheckable":_vm.record.options.treeCheckable,"treeData":!_vm.record.options.dynamic
      ? _vm.record.options.options
      : _vm.dynamicData[_vm.record.options.dynamicKey]
      ? _vm.dynamicData[_vm.record.options.dynamicKey]
      : [],"disabled":_vm.disabled || _vm.record.options.disabled,"allowClear":_vm.record.options.clearable},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'cascader')?_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],style:(("width:" + (_vm.record.options.width))),attrs:{"placeholder":_vm.record.options.placeholder,"showSearch":_vm.record.options.showSearch,"options":!_vm.record.options.dynamic
      ? _vm.record.options.options
      : _vm.dynamicData[_vm.record.options.dynamicKey]
      ? _vm.dynamicData[_vm.record.options.dynamicKey]
      : [],"disabled":_vm.disabled || _vm.record.options.disabled,"allowClear":_vm.record.options.clearable},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):_vm._e()],1):(
  !(_vm.record.options.hidden === true) &&
    (_vm.record.type === 'batch' || _vm.record.type === 'editor')
)?_c('a-form-item',{attrs:{"label":!_vm.record.options.showLabel ? '' : _vm.record.label,"label-col":_vm.formConfig.layout === 'horizontal' && _vm.record.options.showLabel
    ? _vm.formConfig.labelCol
    : {},"wrapper-col":_vm.formConfig.layout === 'horizontal' && _vm.record.options.showLabel
    ? _vm.formConfig.wrapperCol
    : {}}},[(_vm.record.type === 'batch')?_c('ZrBatch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],ref:"ZrBatch",style:(("width:" + (_vm.record.options.width))),attrs:{"record":_vm.record,"config":_vm.config,"parentDisabled":_vm.disabled,"dynamicData":_vm.dynamicData},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):_c('zr-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
    _vm.record.model,
    {
      initialValue: _vm.record.options.defaultValue,
      rules: _vm.record.rules
    }
  ]),expression:"[\n    record.model,\n    {\n      initialValue: record.options.defaultValue,\n      rules: record.rules\n    }\n  ]"}],ref:"ZREditor",style:(("width:" + (_vm.record.options.width))),attrs:{"record":_vm.record,"parentDisabled":_vm.disabled,"dynamicData":_vm.dynamicData},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}})],1):(!(_vm.record.options.hidden === true) && _vm.record.type === 'button')?_c('a-form-item',[_c('a-button',{attrs:{"disabled":_vm.disabled || _vm.record.options.disabled,"type":_vm.record.options.type,"html-type":_vm.record.options.handle === 'submit' ? 'submit' : undefined},domProps:{"textContent":_vm._s(_vm.record.label)},on:{"click":function($event){_vm.record.options.handle === 'submit'
      ? false
      : _vm.record.options.handle === 'reset'
      ? _vm.$emit('handleReset')
      : _vm.dynamicData[_vm.record.options.dynamicFun]
      ? _vm.dynamicData[_vm.record.options.dynamicFun]()
      : false}}})],1):(!(_vm.record.options.hidden === true) && _vm.record.type === 'alert')?_c('a-form-item',[_c('a-alert',{attrs:{"message":_vm.record.label,"description":_vm.record.options.description,"type":_vm.record.options.type,"showIcon":_vm.record.options.showIcon,"closable":_vm.record.options.closable,"banner":_vm.record.options.banner}})],1):(!(_vm.record.options.hidden === true) && _vm.record.type === 'text')?_c('a-form-item',[_c('div',{style:({ textAlign: _vm.record.options.textAlign })},[_c('label',{class:{ 'ant-form-item-required': _vm.record.options.showRequiredMark },domProps:{"textContent":_vm._s(_vm.record.label)}})])]):(!(_vm.record.options.hidden === true) && _vm.record.type === 'html')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.record.options.defaultValue)}}):(_vm.customList.includes(_vm.record.type))?_c('customComponent',{attrs:{"record":_vm.record,"disabled":_vm.disabled,"dynamicData":_vm.dynamicData,"formConfig":_vm.formConfig},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):_c('div',[(
    _vm.record.type === 'divider' &&
      _vm.record.label !== '' &&
      _vm.record.options.orientation
  )?_c('a-divider',{attrs:{"orientation":_vm.record.options.orientation}},[_vm._v(_vm._s(_vm.record.label)+" ")]):(_vm.record.type === 'divider' && _vm.record.label !== '')?_c('a-divider',[_vm._v(_vm._s(_vm.record.label)+" ")]):(_vm.record.type === 'divider' && _vm.record.label === '')?_c('a-divider'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }