<template>
  <div class="ant-layout-sider-children">
    <!-- logo -->
    <div class="alain-pro__sider-logo">
      <router-link class="alain-pro__header-logo-link" to="/">
        <div style="font-size: 18px; color: #fff; font-weight: bolder">
          <!-- <img class="alain-pro__header-logo-expanded" src="http://dianmi365.com/static/global/img/favicon.ico" style="max-height: 40px;margin-top:-5px;" /> -->
          <!-- <img class="alain-pro__header-logo-collapsed" :src="tentantInfo.systemHeadImage" style="max-height: 30px;margin-top:-5px;" /> -->
          <img class="alain-pro__header-logo-expanded" :src="tentantInfo.systemHeadImage" style="max-height: 30px;margin-top:-5px;" />
          <span style="margin-left: 10px;">{{tentantInfo.systemName}}</span>
        </div>
      </router-link>
    </div>

    <!-- sidebar -->
    <yoyo-sidebar-nav></yoyo-sidebar-nav>
  </div>
</template>

<script>
import { YoyoSidebarNav } from "./components";
import { appSessionService } from "@/shared/abp";
import { AppConsts } from "@/abpPro/AppConsts";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  name: "sidebar",
  components: {
    YoyoSidebarNav,
  },
  data() {
    return {
      commonService: null,
      tentantInfo: {
        id: "",
        sysTenantId: "",
        systemName: "裕米灵工",
        systemHeadImage: "https://static.dianmi-north.cn/favicon.ico",
        // systemHeadImage: "",
        tenantLoinLogo: "https://static.dianmi-north.cn/dm_logo.png",
        tenantAboutUsLink: "",
        tenantPrivacyLink: "",
        tanantClauseLink: "",
        productionCompanyName: "点米",
        productionCompanyWebAdress: "http://www.dianmi365.com/",
      },
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);

    let tentantName = appSessionService.getShownTentantName()
    if (tentantName && tentantName !== '') {
      let info = sessionStorage.getItem("tentantInfo")
      if (info) {
        let tentantInfo = JSON.parse(info)
        // console.log('tentantInfo:::', tentantInfo)
        this.tentantInfo = {...tentantInfo, systemHeadImage: `${AppConsts.uploadBaseUrl}${tentantInfo.systemHeadImage}`, tenantLoinLogo: `${AppConsts.uploadBaseUrl}${tentantInfo.tenantLoinLogo}`}
      } else {
        this._getByTentantName(tentantName)
      }
    }
    
  },
  methods: {
     init(tentantInfo) {
      this.tentantInfo = {
        ...tentantInfo,
        systemHeadImage: `${AppConsts.uploadBaseUrl}${tentantInfo.systemHeadImage}`,
        tenantLoinLogo: `${AppConsts.uploadBaseUrl}${tentantInfo.tenantLoinLogo}`,
      };
    },
    _getByTentantName(newTenancyName) {
       let _this = this
        $.get(
        `${this.$apiUrl}/api/services/app/SysInterface/GetByTentantName?tentantName=${newTenancyName}`,
        function (data, status) {
          if (status === 'success') {
            let res = data.result
            if (res) {
              sessionStorage.setItem("tentantInfo", JSON.stringify(res));
              _this.init(res)
            }
          }
        }
      );
      // try {
      //   let res = await this.commonService.get({
      //     url: "/api/services/app/SysInterface/GetByTentantName",
      //     params: {
      //       tentantName: newTenancyName,
      //     },
      //   });
      //   if (res) {
      //     sessionStorage.setItem("tentantInfo", JSON.stringify(res));
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./sidebar.less";
</style>
